
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { CommunicationObserver } from "../App";
import { EventState } from "../constants/enums/state.enum";
import { SignalRConstants } from "../constants/signalr.constants";
import { EventName, EventParam, EventParamType } from "../models/signalr.model";
import { LocalStorageService } from "../services/local-storage.service";
import { AppActions } from "../states/app/app.slice";
import { appDispatch, store } from "../states/store";
import { Result } from "../utils/error-handling.utils";
import { errorlog, eventlog, log } from "../utils/log.utils";
import { buildSignalRConnection } from "./connection";
import { CurrentStateHandlerContext, CurrentStateHandlerContextPrivateObject } from "./current-handler-context";
import { StateContext } from "./state-context";
import { LogServerSideActions } from "../states/app/log-server-side.slice";
import { isApiServerDisconnected } from "../utils/functional.utils";
import { CardReaderReadEntranceHandler } from "./handlers/card-reader-read-entrance.handler";
import { CardReaderReadWarehouseHandler } from "./handlers/card-reader-read-warehouse.handler";
import { StandaloneHandler, StateHandler } from "./handlers/common.handler";
import { EntranceDoorClosedForCheckinHandler } from "./handlers/entrance-door-closed-for-checkin.handler";
import { EntranceDoorClosedForCheckoutHandler } from "./handlers/entrance-door-closed-for-checkout.handler";
import { EntranceDoorOpenedForCheckinHandler } from "./handlers/entrance-door-opened-for-checkin.handler";
import { EntranceDoorButtonPressedInCheckInSessionHandler } from "./handlers/entrance-door-button-pressed-in-check-in-session.handler";
import { DoorButtonPressedNoSessionHandler } from "./handlers/door-button-pressed-no-session.handler";
import { RfidReaderReadHandler } from "./handlers/rfid-reader-read.handler";
import { RoomConfigurateCardReaderReadEntranceHandler } from "./handlers/room-configurate-card-reader-read-entrance.handler";
import { RoomConfigurateCardReaderReadWarehouseHandler } from "./handlers/room-configurate-card-reader-read-warehouse.handler";
import { DefaultStandaloneHandler } from "./handlers/standalone-handlers.handler";
import { WarehouseDoorClosedForCheckinHandler } from "./handlers/warehouse-door-closed-for-checkin.handler";
import { WarehouseDoorClosedForCheckoutHandler } from "./handlers/warehouse-door-closed-for-checkout.handler";
import { WarehouseDoorOpenedForCheckoutHandler } from "./handlers/warehouse-door-opened-for-checkout.handler";
import { WarehouseDoorButtonPressedInCheckInSessionHandler } from "./handlers/warehouse-door-button-pressed-in-check-in-session.handler";
import { EntranceDoorButtonPressedInCheckOutSessionHandler } from "./handlers/entrance-door-button-pressed-in-check-out-session.handler";
import { WarehouseDoorButtonPressedInCheckOutSessionHandler } from "./handlers/warehouse-door-button-pressed-in-check-out-session.handler";
import { EmergencyEntranceDoorCheckInSessionHandler } from "./handlers/emergency-entrance-door-check-in-session.handler";
import { EmergencyWarehouseDoorCheckInSessionHandler } from "./handlers/emergency-warehouse-door-check-in-session.handler";
import { EmergencyEntranceDoorCheckOutSessionHandler } from "./handlers/emergency-entrance-door-check-out-session.handler";
import { EmergencyWarehouseDoorCheckOutSessionHandler } from "./handlers/emergency-warehouse-door-check-out-session.handler";
import { EmergencyWarehouseDoorBeforeScanningCheckOutSessionHandler } from "./handlers/emergency-warehouse-door-before-scanning-check-out-session.handler";
import { EmergencyEntranceDoorBeforeScanningCheckOutSessionHandler } from "./handlers/emergency-entrance-door-before-scanning-check-out-session.handler";
import { EmergencyWarehouseDoorBeforeScanningCheckInSessionHandler } from "./handlers/emergency-warehouse-door-before-scanning-check-in-session.handler";
import { EmergencyEntranceDoorBeforeScanningCheckInSessionHandler } from "./handlers/emergency-entrance-door-before-scanning-check-in-session.handler";
import { shouldStartStandbyTimerBySignalRIncomingEvent } from "../utils/signalr.utils";
import { StandbyTimer } from "./standby-timer";
import { DoorButtonPressedBeforeCheckOutScanningSessionHandler } from "./handlers/door-button-pressed-before-checkout-scanning-session.handler";
import { DoorButtonPressedBeforeCheckInScanningSessionHandler } from "./handlers/door-button-pressed-before-check-in-scanning-session.handler";
import { StateHandlerRunModeEnum } from "../constants/enums/handler-type.enum";
import { LogRecordFrom } from "../utils/debug.utils";

export type StateHandlerResult = EventState[] | null;
export type StateHandlerFn<T extends EventName> =
  (context: CurrentStateHandlerContext, params: EventParam<T>) => Promise<StateHandlerResult>;

export type StandaloneHandlerFn<T extends EventName> =
  (context: StateContext, params: EventParam<T>) => Promise<StateHandlerResult>;

export class StateHandlerObserverClass {
  context: StateContext = new StateContext();
  handlers: Map<EventName, StateHandler<EventName>[]> = new Map();
  standaloneEventHandler: StandaloneHandler<EventName> = DefaultStandaloneHandler;

  conn: HubConnection = buildSignalRConnection();
  isConnected: boolean = false;

  private onCreateNewConnectionHandlers: ((conn: HubConnection) => void)[] = [];

  get isSignalRConnected() {
    return this.isConnected;
  }

  constructor() {
    this.registerHandlers();
    this.registerSignalREvents();

    this.connectToSignalR();
  }

  private connectToSignalR() {
    const startConnectAt = Date.now();

    this.conn.start()
      .then(() => this.updateConnectionState())
      .then(() => this.requestRoomInfo())
      .catch((err) => {
        this.onDisconnected();
        
        // Calculate when should we retry to conenct, since CORS may make this a recursive call.
        // This would be a disaster!
        const diff = Date.now() - startConnectAt;
        const sleepBeforeRetry = diff > SignalRConstants.Default.RetryDelayInMilliseconds
          ? 0
          : SignalRConstants.Default.RetryDelayInMilliseconds - diff;
        errorlog('failed connection, retry after', sleepBeforeRetry, 'ms, err:', err);
        setTimeout(() => this.connectToSignalR(), sleepBeforeRetry);
      });

      this.conn.onreconnecting(() => this.onDisconnected());
      this.conn.onreconnected(() => this.onReconnected());
      this.conn.onclose(() => {
        log('invoke onclose to restart connection');
        this.connectToSignalR();
      })
  }

  private async onDisconnected() {
    const connectState = store.getState().app.isSignalRServerConnected;

    if (!connectState) {
      // If it's already been disconnected. Don't display another message.
      errorlog(`SignalR server is disconnected`);
    }

    appDispatch(AppActions.setSignalRConnectionState(false));

    this.context.session.emergencyCancel();
  }

  private async onReconnected() {
    log(`Reconnected to SignalR server succesfully.`);
    appDispatch(AppActions.setSignalRConnectionState(true));

    // Send `ResetToStandby` when reconnected.
    await CommunicationObserver.resetToStandBy();
  }

  registerHandler<T extends EventName>(stateHandler: StateHandler<T>) {
    const handlers = this.handlers.get(stateHandler.event);

    if (!handlers?.length) {
      this.handlers.set(stateHandler.event, [stateHandler as any]); // Evil cast >:)
      return;
    }

    handlers.push(stateHandler as any); // Evil cast >:) again
  }

  onCreateNewConnection(callback: (conn: HubConnection) => void) {
    this.onCreateNewConnectionHandlers.push(callback);
  }

  registerStandaloneHandler<T extends EventName>(handler: StandaloneHandler<T>) {
    this.standaloneEventHandler = handler;
  }
  
  private registerSignalREvents() {
    this.conn.onreconnected(() => this.updateConnectionState());
    this.conn.onclose(err => {
      log(`connection is closed, error:`, err)
      this.updateConnectionState();
    });
    
    // Eliminate duplicates.
    const signalREvents = Array.from(new Set(
      Array.from(this.handlers.keys())
        .concat(this.standaloneEventHandler.events)
      )
    );
    
    const bindEvent = (event: EventName) => this.conn.on(event, (...args) => this.onReceiveMessage(event, args));

    signalREvents.forEach(bindEvent);

    log(`bound`, signalREvents.length, `event(s):`, signalREvents);
    log(`bound`, this.standaloneEventHandler.events.length, `standalone event(s):`, this.standaloneEventHandler.events)

    if (LocalStorageService.shouldLog()) {
      const statesRegistered = Array.from(this.handlers.values()).flat().map(handler => handler.state);

      log(`registered`, statesRegistered.length, `state(s):`, statesRegistered);
    }
  }
  
  private updateConnectionState() {
    const oldStatus = this.isConnected;
    this.isConnected = this.conn.state === HubConnectionState.Connected;

    log(`connection state changed: from:`, oldStatus, `=> to:`, this.isConnected, `(current state: ${this.conn.state})`);

    appDispatch(AppActions.setSignalRConnectionState(this.isConnected));
  }
  
  private registerHandlers() {
    // Room configuration handlers (may borrow some handlers from check in/out flow):
    this.registerHandler(RoomConfigurateCardReaderReadEntranceHandler);
    this.registerHandler(RoomConfigurateCardReaderReadWarehouseHandler);

    // Main flow check in/out handlers:
    this.registerHandler(CardReaderReadEntranceHandler);
    this.registerHandler(EntranceDoorOpenedForCheckinHandler);
    this.registerHandler(EntranceDoorClosedForCheckinHandler);
    this.registerHandler(WarehouseDoorClosedForCheckinHandler);
    this.registerHandler(RfidReaderReadHandler);
    this.registerHandler(CardReaderReadWarehouseHandler);
    this.registerHandler(WarehouseDoorOpenedForCheckoutHandler);
    this.registerHandler(WarehouseDoorClosedForCheckoutHandler);
    this.registerHandler(EntranceDoorClosedForCheckoutHandler);

    // Door buttons handlers:
    this.registerHandler(DoorButtonPressedNoSessionHandler);
    this.registerHandler(DoorButtonPressedBeforeCheckInScanningSessionHandler);
    this.registerHandler(DoorButtonPressedBeforeCheckOutScanningSessionHandler);
    this.registerHandler(EntranceDoorButtonPressedInCheckInSessionHandler);
    this.registerHandler(WarehouseDoorButtonPressedInCheckInSessionHandler);
    this.registerHandler(EntranceDoorButtonPressedInCheckOutSessionHandler);
    this.registerHandler(WarehouseDoorButtonPressedInCheckOutSessionHandler);

    // Emergency handlers:
    this.registerHandler(EmergencyEntranceDoorCheckInSessionHandler);
    this.registerHandler(EmergencyWarehouseDoorCheckInSessionHandler);
    this.registerHandler(EmergencyEntranceDoorCheckOutSessionHandler);
    this.registerHandler(EmergencyWarehouseDoorCheckOutSessionHandler);
    
    this.registerHandler(EmergencyWarehouseDoorBeforeScanningCheckInSessionHandler);
    this.registerHandler(EmergencyEntranceDoorBeforeScanningCheckInSessionHandler);
    
    this.registerHandler(EmergencyWarehouseDoorBeforeScanningCheckOutSessionHandler);
    this.registerHandler(EmergencyEntranceDoorBeforeScanningCheckOutSessionHandler);
  }

  private async onReceiveMessage(event: EventName, args: any[]) {
    appDispatch(LogServerSideActions.addLog({eventName: event, content: JSON.stringify(args), createdDateUtc: new Date()}))

    LocalStorageService.addLogRecord({
      from: LogRecordFrom.SignalR,
      eventName: event,
      params: args,
    })

    const parseParamsFn = SignalRConstants.EventParamsTransform[event];

    if (!parseParamsFn) {
      eventlog(event, `:: missing params parsing function in SignalRConstants.EventParamsTransform`);
      return;
    }

    const params = parseParamsFn(args);

    
    if (isApiServerDisconnected()) {
      eventlog(params.event, `:: run default handler because API server is disconnected!`);
      return this.defaultHandlerWrapper(params);
    }
    
    const handlersByEvent = this.handlers.get(params.event);
    if (!handlersByEvent?.length) {
      eventlog(params.event, `:: no handler found for this event`);
      return this.defaultHandlerWrapper(params);
    }
    
    const stateEventHandler = handlersByEvent.find(handler => handler.validator(this.context, params, handler));
    if (!stateEventHandler) {
      eventlog(params.event, `:: no state handler found for this event (validators failed), possible handlers:`, handlersByEvent);
      return this.defaultHandlerWrapper(params);
    }
    
    if (!this.context.isStateExpected(stateEventHandler?.state)) {
      eventlog(params.event, `:: is ignored; expect:`, this.context.expectStates);
      return this.defaultHandlerWrapper(params);
    }

    this.stopStandbyTimer();
    
    // this.startStandbyTimer can't run before this.runHandler because some API call in the stateHandler may eat up
    // time in the standbyTimer, then result in inconsistent user experience.
    await this.runHandler(stateEventHandler, params)
      .finally(() => this.startStandbyTimer(params));
  }

  private async runHandler<T extends EventName>(handler: StateHandler<T>, params: EventParam<T>) {
    const activeHandlerNo = this.context.registerActiveHandler(handler.state);

    eventlog(params.event, `:: running state:`, handler.state, `:: params:`, params);
    
    const mutableObject = {
      exception: undefined,
    } as CurrentStateHandlerContextPrivateObject;

    const currentHandlerContext = new CurrentStateHandlerContext(this.context, activeHandlerNo, mutableObject);
    
    const result = await Result.fromAPIPromise(handler.handler(currentHandlerContext, params));

    if (result.isOk) {
      const resultData = result.data;
      eventlog(params.event, `:: expect next state (for state: ${handler.state}):`,
        ...(result === null ? ["<unchanged>", this.context.expectStates] : [resultData]));

      this.context.updateExpectNextStates(resultData);
    }
    else {
      const err = result.data;

      eventlog(params.event, `:: the state handler threw an exception:`, err, `| params:`, params);

      mutableObject.exception = err;
    }

    // Finally:
    const deferFn = this.context.getStateDeferFn(activeHandlerNo);
    deferFn?.();

    this.context.removeActiveHandler(activeHandlerNo);
  }

  private async defaultHandlerWrapper<T extends EventParamType>(params: T) {
    return this.runDefaultHandler(params).finally(() => this.startStandbyTimer(params))
  }

  private async runDefaultHandler<T extends EventParamType>(params: T) {
    // TODO(knta): make it to something more elegant!
    if (isApiServerDisconnected()) {
      return this.standaloneEventHandler.handler(this.context, params);
    }

    const standalonableHandler = this.handlers
      .get(params.event)
      ?.filter(handler => handler.mode === StateHandlerRunModeEnum.Standalone)
      .find(handler => handler.validator(this.context, params, handler));
    
    if (standalonableHandler) {
      eventlog(params.event, `found handler to run this event as standlone`, );

      return this.runHandler(standalonableHandler, params).finally(() => {
        this.startStandbyTimer(params);
      });
    }

    return this.standaloneEventHandler.handler(this.context, params);
  }

  private requestRoomInfo() {
    return CommunicationObserver.requestStatus();
  }

  /**
   * Must run in the context of valid state.
   * 
   * Can't include this.stopStandbyTimer in this function, because it will cancel the timer which just has been start in the stateHandler. 
   */
  private startStandbyTimer<T extends EventName>(params: EventParam<T>) {
    if (shouldStartStandbyTimerBySignalRIncomingEvent(params)) {
      // Mechanic: only starts when there is a request to open door (outside and inside) or an incoming open door event.
      // Expected: only starts when:
      //  - Door opened outside of session (user just end of session or idle).
      //  - Door opened before scanning session.

      const ctx = this.context;
      const session = ctx.session;
      const isExpectedStandbyTimerStart = !session.hasSession || !session.isScanning; // except start timeout
      if (!isExpectedStandbyTimerStart) {
        errorlog(`StateHandlerObserver::startStandbyTimer: unexpected standby timer start!`)
      }

      StandbyTimer.cancel().onTimeout(() => {
        log(`StateHandlerObserver::startStandbyTimer: ending session.`);
        this.context.session.end();
      }).start();
      return;
    }
  }

  private stopStandbyTimer() {
    StandbyTimer.cancel();
  }
}
