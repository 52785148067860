import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EventLog } from '../../models/event-log.model';
import { appDispatch, store } from '../store';
import { HttpClient } from '@onix/common';
import { ApiConstants } from '../../constants/api.constants';
import { LocalStorageService } from '../../services/local-storage.service';

const submitWaitingTime = 30000;
const maximumSubmitItem = 20;
export interface LogServerSideState {
  data: EventLog[];
  timeoutId: null | ReturnType<typeof setTimeout>;
}
const initialState: LogServerSideState = {
  data: [],
  timeoutId: null,
};
const LogServerSideSlice = createSlice({
  name: 'logServerSide',
  initialState,
  reducers: {
    addLog: (state, { payload }: PayloadAction<EventLog>) => {
      if (LocalStorageService.shouldLogServerSide()) {
        state.data.push(payload);

        if (state.data.length >= maximumSubmitItem) {
          setTimeout(() => {
            submitData();
          }, 100);
          return;
        } else if (state.timeoutId) {
          clearTimeout(state.timeoutId);
        }
        state.timeoutId = setTimeout(() => {
          submitData();
        }, submitWaitingTime);
      }
    },
    clear: (state) => {
      state.data = [];
      if (state.timeoutId) clearTimeout(state.timeoutId);
    },
  },
});
export const submitData = async () => {
  HttpClient.postAsync(ApiConstants.deviceLog.createLog(), { roomId: store.getState().app.roomId, eventLogs: store.getState().logServerSide.data }, null);
  appDispatch(LogServerSideActions.clear());
};
export const LogServerSideActions = LogServerSideSlice.actions;
export const LogServerSideReducer = LogServerSideSlice.reducer;
