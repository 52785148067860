import { StateHandlerRunModeEnum } from "../../constants/enums/handler-type.enum";
import { EventState } from "../../constants/enums/state.enum";
import { SignalRConstants } from "../../constants/signalr.constants";
import { hasInvalidEquipmentInCheckoutFlow } from "../../utils/equipment.utils";
import { handlerlog } from "../../utils/log.utils";
import { and, entranceDoorButtonEvent, hasSession, isCheckOutFlow, roomConfigured, scanSessionIsOn } from "../validators";
import { ExpectNextStates, StateHandler } from "./common.handler";

export const EntranceDoorButtonPressedInCheckOutSessionHandler: StateHandler<typeof SignalRConstants.Events.DoorButtonPressed> = {
  state: EventState.EntranceDoorButtonPressedInCheckOutSession,
  event: SignalRConstants.Events.DoorButtonPressed,
  mode: StateHandlerRunModeEnum.Standalone,
  validator: and(
    roomConfigured,
    hasSession,
    scanSessionIsOn,          // Both doors are closed!
    entranceDoorButtonEvent,  // Only accept the warehouse button (btw, we don't have to handle entrance door for check in flow).
    isCheckOutFlow,           // Check in flow only!
  ),
  async handler(ctx) {
    if (hasInvalidEquipmentInCheckoutFlow()) {      
      handlerlog(this.state, `there is invalid equipment in checkout flow. Ignore entrace door button pressing event.`);

      return ExpectNextStates.AnyStates;
    }

    handlerlog(this.state, `all valid equipment in checkout flow. Submitting and ending session!`);

    await ctx.session.end();

    return ExpectNextStates.AnyStates;
  },
};
