import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckInOutStateEnum } from "../../constants/enums/app-state.enum";
import { AuthenticationInfo } from "../../models/authentication-info.model";
import { EquipmentInfo } from "../../models/equipment-info.model";
import { RootState } from '../store';
import { TimerConstants } from '../../constants/timer.constants';

export interface CheckInOutState {
  checkInOutState: CheckInOutStateEnum,
  equipmentInfo: EquipmentInfo[],
  remainingSeconds: number,
  confirmInactiveRemainingSeconds: number,
  onConfirmResult: VoidFunction,
  authenInfo: AuthenticationInfo | undefined,
}

const initialState: CheckInOutState = {
  checkInOutState: CheckInOutStateEnum.Uninit,
  equipmentInfo: [],
  remainingSeconds: 0,
  confirmInactiveRemainingSeconds: TimerConstants.ConfirmInactivityTimeoutInSecond,
  onConfirmResult: () => {},
  authenInfo: undefined,
};

const CheckInOutSlice = createSlice({
  name: "checkInOut",
  initialState: initialState,
  reducers: {
    clearState(state) {
      state.checkInOutState = CheckInOutStateEnum.Uninit;
      state.equipmentInfo = [];
      state.authenInfo = undefined;
    },
    switchToWaitForClosingDoors(state) {
      state.checkInOutState = CheckInOutStateEnum.WaitForClosingDoors;
    },
    switchToWaitForConfirmingResults(state) {
      state.checkInOutState = CheckInOutStateEnum.WaitForConfirmingResults;
    },
    switchToScanningComplete(state) {
      state.checkInOutState = CheckInOutStateEnum.ScanningComplete;
    },
    addEquipmentInfo(state, { payload }: PayloadAction<EquipmentInfo>) {
      state.equipmentInfo = [...state.equipmentInfo.filter(eq => eq.globalID !== payload.globalID), payload];
    },
    toggleNeedService(state, { payload }: PayloadAction<EquipmentInfo>) {
      const canSetNeedService = state.checkInOutState !== CheckInOutStateEnum.WaitForConfirmingResults
        && state.equipmentInfo.length > 0
      if (canSetNeedService) {
        return;
      }

      const infoList = state.equipmentInfo.slice();

      const editedEqIdx = infoList.findIndex(eq => eq.globalID === payload.globalID);
      if (editedEqIdx >= 0) {
        const isNeedService = !(infoList[editedEqIdx].isNeedService);

        infoList[editedEqIdx] = {
          ...infoList[editedEqIdx],
          isNeedService: isNeedService,
        }
      }

      state.equipmentInfo = infoList;
    },
    setRemainingSeconds(state, { payload }: PayloadAction<number>) {
      state.remainingSeconds = payload;
    },
    setConfirmInactiveRemainingSeconds(state, { payload }: PayloadAction<number>) {
      state.confirmInactiveRemainingSeconds = payload
    },
    setOnConfirmResult(state, { payload }: PayloadAction<CheckInOutState["onConfirmResult"]>) {
      state.onConfirmResult = payload;
    },
    setAuthenInfo(state, { payload }: PayloadAction<AuthenticationInfo>) {
      state.authenInfo = payload;
    },
  },
});

export const CheckInOutActions = CheckInOutSlice.actions;
export const CheckInOutReducer = CheckInOutSlice.reducer;

// SELECTORS

const selectSelf = (state: RootState) => state.checkInOut;
export const checkInOutStateSelector = createSelector(selectSelf, (state) => state.checkInOutState);
export const equipmentInfoListSelector = createSelector(selectSelf, (state) => state.equipmentInfo);
export const checkInOutInfoSelector = createSelector(selectSelf, (state) => state);
export const authenInfoSelector = createSelector(selectSelf, (state) => state.authenInfo);
