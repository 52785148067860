import { HubConnection } from "@microsoft/signalr";
import { AppStateEnum } from "../constants/enums/app-state.enum";
import { DoorEnum, DoorLocationEnum } from "../constants/enums/signalr.enum";
import { SignalRConstants } from "../constants/signalr.constants";
import { EventTypeKey, SignalRRequest } from "../models/signalr.model";
import { log } from "../utils/log.utils";
import { StateHandlerObserverClass } from "./state-handler-observer";
import { appDispatch } from "../states/store";
import { LogServerSideActions } from "../states/app/log-server-side.slice";
import { shouldStartStandbyTimerBySignalRRequest } from "../utils/signalr.utils";
import { StandbyTimer } from "./standby-timer";
import { StateHandlerObserver } from "../App";
import { toast } from 'react-toastify';
import { SignOutIcon } from "../components/Button/IconButton";
import i18next from 'i18next'
import { LocalStorageService } from "../services/local-storage.service";
import { LogRecordFrom } from "../utils/debug.utils";
export class CommunicationObserverClass {
  private conn: HubConnection;

  constructor(stateHandlerObserver: StateHandlerObserverClass) {
    this.conn = stateHandlerObserver.conn;

    stateHandlerObserver.onCreateNewConnection((conn) => this.updateConnection(conn));
  }

  updateConnection(conn: HubConnection) {
    this.conn = conn;
  }

  private send(eventType: SignalRRequest, ...params: any[]) {
    if (shouldStartStandbyTimerBySignalRRequest(eventType)) {
      // start standby timer whenever DoorOpenRequest is sent.
      StandbyTimer.onTimeout(async () => {
        log(`CommunicationObserver::send: end session and reset to standby because of request:`, eventType, params);
        await StateHandlerObserver.context.session.end();
      }).start();
    }

    appDispatch(LogServerSideActions.addLog({eventName: eventType, content: JSON.stringify(params), createdDateUtc: new Date()}))

    this.logClientSide(eventType, ...params)

    return this.conn.send(eventType, ...params);
  }

  requestStatus() {
    return this.send(SignalRConstants.RequestEvents.RequestStatus);
  }

  /**
   * Open entrance door outside. (quote from the API documentation...)
   */
  openEntranceDoorOutside() {
    return this.send(SignalRConstants.RequestEvents.DoorOpenRequest, DoorEnum.Entrance, DoorLocationEnum.Outside, false);
  }

  openEntranceDoorInside() {
    this.showToastMessage('AppInspect.EntranceDoorOpened', SignOutIcon);
    return this.send(SignalRConstants.RequestEvents.DoorOpenRequest, DoorEnum.Entrance, DoorLocationEnum.Inside, false);
  }

  openWarehouseDoorOutside() {
    return this.send(SignalRConstants.RequestEvents.DoorOpenRequest, DoorEnum.Warehouse, DoorLocationEnum.Outside, false);
  }

  openWarehouseDoorInside() {
    this.showToastMessage('AppInspect.WarehouseDoorOpened', SignOutIcon);
    return this.send(SignalRConstants.RequestEvents.DoorOpenRequest, DoorEnum.Warehouse, DoorLocationEnum.Inside, false);
  }

  openDoorInside(door: DoorEnum) {
    let messageKey = '';
    if(door === DoorEnum.Entrance){
      messageKey =  'AppInspect.EntranceDoorOpened';
    } else{
      messageKey =  'AppInspect.WarehouseDoorOpened';
    }
    this.showToastMessage(messageKey, SignOutIcon);
    return this.send(SignalRConstants.RequestEvents.DoorOpenRequest, door, DoorLocationEnum.Inside, false);
  }

  entranceTurnOnMagnetLock() {
    return this.entranceMagnetLockChangeRequest(true);
  }

  warehouseTurnOnMagnetLock() {
    return this.warehouseMagnetLockChangeRequest(true);
  }

  turnOnMagnetLockForOppositeDoor(door: DoorEnum) {
    if (door === DoorEnum.Entrance) {
      return this.warehouseTurnOnMagnetLock();
    }

    return this.entranceTurnOnMagnetLock();
  }

  private entranceMagnetLockChangeRequest(turnMagnetOn: boolean) {
    return this.send(SignalRConstants.RequestEvents.MagnetLockChangeRequest, DoorEnum.Entrance, turnMagnetOn);
  }

  private warehouseMagnetLockChangeRequest(turnMagnetOn: boolean) {
    return this.send(SignalRConstants.RequestEvents.MagnetLockChangeRequest, DoorEnum.Warehouse, turnMagnetOn);
  }

  openDoorFromInsideAfterState<T extends AppStateEnum.CheckIn | AppStateEnum.CheckOut>(state: T) {
    if (state === AppStateEnum.CheckIn) {
      log(`${this.openDoorFromInsideAfterState.name}: open warehouse door from inside (state: ${state})`);
      return this.openWarehouseDoorInside();
    }

    log(`${this.openDoorFromInsideAfterState.name}: open entrance door from inside (state: ${state})`);
    return this.openEntranceDoorInside()
  }

  rfidReaderStart() {
    return this.send(SignalRConstants.RequestEvents.RfidReaderStartRequest);
  }

  rfidReaderStop() {
    return this.send(SignalRConstants.RequestEvents.RfidReaderStopRequest);
  }

  resetToStandBy() {
    return this.send(SignalRConstants.RequestEvents.ResetToStandBy);
  }

  /**
   * Invoke an event on this client and of course, it won't send anything to server/localhost's hub.
   */
  invoke(eventType: EventTypeKey, ...params: any[]) {
    this.conn.invoke(eventType, ...params)
  }
  showToastMessage(messageKey: string, icon: any){
    toast.dismiss();
    const closedTime = 14000;
    toast.info(i18next.t(messageKey), {
        icon: icon ,
        position: "bottom-right",
        autoClose: closedTime,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        theme: "colored",
        });
    }
  
  private logClientSide(eventType: SignalRRequest, ...params: any[]) {
    LocalStorageService.addLogRecord({
      from: LogRecordFrom.Client,
      eventName: eventType,
      params,
    })
  }
}
