import { CommunicationObserver, StateHandlerObserver } from "../App"
import { TimerConstants } from "../constants/timer.constants"
import { getContext } from "../utils/common.utils"
import { SetTimeoutType } from "../utils/functional.utils"
import { log } from "../utils/log.utils"

class StandbyTimerClass {
  private timeoutInSecond: number = TimerConstants.DefaultIdleTimeoutInSecond
  private timeoutId: SetTimeoutType | undefined = undefined
  private onTimeoutFns: VoidFunction[] = []

  get hasTimer() {
    return this.timeoutId !== undefined
  }

  get timeoutInMillis() {
    return this.timeoutInSecond * TimerConstants.SecondToMillis
  }

  /**
   * NOTE: Ensure properly clean up before start a new timer.
   * 
   * This method is also handling restart mechanism.
   */
  start() {
    this.timeoutInSecond = getContext().session.idleTimeoutInSeconds

    if (this.timeoutId) {
      log(`StandbyTimer::start: stop current standby timer.`)
    }

    // We don't need to clean up since it auto cleans up when the timer is canceled (any event which is not open)
    clearTimeout(this.timeoutId)

    log(`StandbyTimer: the timer has started:`, this.timeoutInSecond, `second(s)`)

    this.timeoutId = setTimeout(() => {
      if (!StateHandlerObserver.context.isRoomConfigured) {
        log(`StandbyTimer: the room is not configured, ABORT sending ResetToStandby request.`)
        return
      }

      log(`StandbyTimer: timed-out, sending ResetToStandby request.`)
      this.standby()
      this.onTimeoutFns.forEach(fn => fn())
      this.cleanup()
    }, this.timeoutInMillis)

    return this
  }

  cancel() {
    if (this.timeoutId === undefined) {
      return this
    }

    log(`StandbyTimer: the timer has canceled.`)
    this.cleanup()
    return this
  }

  setTimeout(seconds: number) {
    this.timeoutInSecond = seconds
    return this
  }

  onTimeout(onTimeoutFn: VoidFunction) {
    this.onTimeoutFns.push(onTimeoutFn)
    return this
  }

  private standby() {
    return CommunicationObserver.resetToStandBy()
  }

  private cleanup() {
    clearTimeout(this.timeoutId)
    this.timeoutId = undefined
    this.onTimeoutFns = []
  }
}

export const StandbyTimer = new StandbyTimerClass()
