import { HubConnectionState } from "@microsoft/signalr";
import { DoorState } from "../models/door-state.model";
import { EventParamsTransform, EventType } from "../models/signalr.model";
import { parseDoorEnum } from "../utils/signalr.utils";
import { TimerConstants } from "./timer.constants";

export const SignalRConstants = {
  Default: {
    SignalRUrl: "http://localhost:8195/hub/app",
    ConfirmTimeoutInSecond: TimerConstants.DefaultConfirmTimeoutInSecond,
    IdleTimeoutInSecond: TimerConstants.DefaultIdleTimeoutInSecond,
    LanguageId: 1,
    RetryDelayInMilliseconds: 2000,
  },
  DefaultDoorState: {
    entranceOpened: true,
    warehouseOpened: true,
  } satisfies DoorState, 
  HubDisconnectedStates: [HubConnectionState.Disconnected],
  Events: {
    Status: "Status",
    CardReaderRead: "CardReaderRead",
    DoorChangedEvent: "DoorChangedEvent",
    RfidReaderRead: "RfidReaderRead",
    DoorButtonPressed: "DoorButtonPressed",
  } satisfies EventType,
  RequestEvents: {
    RequestStatus: "RequestStatus" as const,
    DoorOpenRequest: "DoorOpenRequest" as const,
    MagnetLockChangeRequest: "MagnetLockChangeRequest" as const,
    RfidReaderStartRequest: "RfidReaderStartRequest" as const,
    RfidReaderStopRequest: "RfidReaderStopRequest" as const,
    ResetToStandBy: "ResetToStandBy" as const,
  },
  EventParamsTransform: {
    Status:           (args) => ({ event: "Status", status: args[0] }),
    CardReaderRead:   (args) => ({ event: "CardReaderRead", door: parseDoorEnum(args[0].door)!, chipId: args[0].chipId }),
    DoorChangedEvent: (args) => ({ event: "DoorChangedEvent", door: parseDoorEnum(args[0].door)!, isOpen: args[0].open }),
    RfidReaderRead:   (args) => ({ event: "RfidReaderRead", serialNumber: args[0].serialNumber }),
    DoorButtonPressed: (args) => ({ event: "DoorButtonPressed", door: parseDoorEnum(args[0].door)! }),
  } satisfies EventParamsTransform,
};
