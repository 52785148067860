import { useCallback } from "react"
import { f } from "../utils/language.utils"

/** Translation function. */
export type UseFormatTranslationProps = (key: string) => string

export const useFormatTranslation = (props: UseFormatTranslationProps) => {
  const formattedTranslate = useCallback(f(props), [props])

  return formattedTranslate
}
