
export enum SessionStateEnum {
  /**
   * Represent: there is no previous state.
   */
  Uninit = 'uninit',

  /**
   * The session is opened.
   */
  Opened = 'open',

  /**
   * The session is closed.
   */
  Closed = 'close',
}
