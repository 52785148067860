import { CommunicationObserver, ConfirmTimer, InactivityTimer, StateHandlerObserver } from "../App"
import { i18n } from "../constants/languages.constants"
import { TimerConstants } from "../constants/timer.constants"
import { LocalStorageService } from "../services/local-storage.service"
import { CheckInOutActions } from "../states/app/check-in-out-state.slice"
import { appDispatch } from "../states/store"
import { log } from "./log.utils"
import { getGlobalStateContext } from "./signalr.utils"

export enum LogRecordFrom {
  SignalR = "signal-r",
  Client = "onix-checkout-app",
}
export type LogRecord = {
  from: LogRecordFrom,
  eventName: string,
  params: Record<string, any>,
  date: string,
}

export const initDebug = () => {
  const w = window as any
  w._debug = {
    communicationObs: CommunicationObserver,
    i18n,
    ctx: getGlobalStateContext(),
    startRecord() {
      return LocalStorageService.setLogRecord(true)
    },
    stopRecord() {
      return LocalStorageService.setLogRecord(false)
    },
    showRecord() {
      const records = LocalStorageService.getLogRecord()

      log("LogRecord:\n")
      console.log(JSON.stringify(records, undefined, 2))
    },
    clearRecord() {
      return LocalStorageService.clearLogRecord()
    },
    startNewRecord() {
      this.stopRecord()
      this.clearRecord()
      this.startRecord()
    },
    setInactivityTimeout(seconds: number) {
      TimerConstants.InactivityTimeoutInSecond = seconds
    },
    setInactivityTimeoutPopup(seconds: number) {
      TimerConstants.ConfirmInactivityTimeoutInSecond = seconds
    },
    simulateRfidEvent(maxEq?: number | undefined) {
      const intervalMs = 1000
      let counter = 0
      const ctx = StateHandlerObserver.context
      const intervalId = setInterval(() => {
        counter += 1
        if (maxEq && counter >= maxEq) {
          clearInterval(intervalId)
          return
        }

        ctx.session.registerScannedId(`fake-eq:${counter}`);
    
        // We accept all the result from the API, it will displayed as "Not found equipment".
        appDispatch(CheckInOutActions.addEquipmentInfo({
          isNotFound: false,
          type: `fake-eq-type:${counter}`,
          typeInfo: `fake-model:${counter}`,
          globalID: `fake-eq:${counter}`,
        } as any));
      }, intervalMs)
      log(`DEBUG: add 100 fake items into list with interval =`, intervalMs, `ms | intervalId:`, intervalId)
      ctx.session.onSessionEnd(() => {
        clearInterval(intervalId)
      })
    },
    InactivityTimer,
    ConfirmTimer,
  }
}
