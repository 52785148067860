import { DoorEnum } from "../constants/enums/signalr.enum"
import { SignalRConstants } from "../constants/signalr.constants"
import { DoorChangedEventParam } from "../models/signalr.model"
import { LocalStorageService } from "../services/local-storage.service"
import { log } from "../utils/log.utils"

export class DoorSession {
  // Default state for both door is opened, so users must have to open and close both doors for the first use.

  private entranceDoorOpened: boolean = SignalRConstants.DefaultDoorState.entranceOpened
  private warehouseDoorOpened: boolean = SignalRConstants.DefaultDoorState.warehouseOpened
  private recentlyClosedDoor: DoorEnum | undefined = undefined

  /**
   * DoorSession is created when the StateContext is initialized.
   * It's also load the door saved value
   */
  constructor() {
    this.loadDoorStateFromLocalStorage()
  }

  get isEntranceDoorOpened() { return this.entranceDoorOpened; }
  get isWarehouseDoorOpened() { return this.warehouseDoorOpened; }

  get latestClosedDoor() { return this.recentlyClosedDoor; }

  updateDoorState(state: DoorChangedEventParam) {
    if (state.door === DoorEnum.Entrance) {
      this.entranceDoorOpened = state.isOpen
    }
    else if (state.door === DoorEnum.Warehouse) {
      this.warehouseDoorOpened = state.isOpen
    }

    if (!state.isOpen) {
      this.recentlyClosedDoor = state.door
    }

    this.saveDoorStateToLocalStorage()
  }

  loadDoorStateFromLocalStorage() {
    const doorState = LocalStorageService.getDoorState()

    this.entranceDoorOpened = doorState.entranceOpened
    this.warehouseDoorOpened = doorState.warehouseOpened

    log(
      `DoorSession::loadDoorStateFromLocalStorage: entranceDoorOpened:`,
      this.entranceDoorOpened,
      `; warehouseDoorOpened:`,
      this.warehouseDoorOpened
    )
  }

  private saveDoorStateToLocalStorage() {
    LocalStorageService.setDoorState({
      entranceOpened: this.entranceDoorOpened,
      warehouseOpened: this.warehouseDoorOpened
    })
  }
}
