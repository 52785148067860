import { Icon, IIconProps, IStyle } from '@fluentui/react';
import { css } from '../../utils/css.utils';
import './index.scss';

export interface IconButtonProps extends IIconProps {
  iconName: string;
  classNames?: string;
  size?: string | number;
}

export const IconButton = (props: IconButtonProps) => {
  const inlineStyle: IStyle = {
    fontSize: props.size,
    ...props.styles,
  };

  return (
    <button className={css('otg-icon-button', props.classNames)}>
      <Icon {...props} styles={{ root: inlineStyle }} />
    </button>
  );
};

const createIconButtonComponent = (iconName: string, displayName?: string) => {
  const Component = (props: Omit<IconButtonProps, 'iconName'>) => <IconButton {...props} iconName={iconName} />;

  Component.displayName = displayName ?? iconName;

  return Component;
};

export const PrevButton = createIconButtonComponent('ChevronLeftMed', 'PrevButton');
export const NextButton = createIconButtonComponent('ChevronRightMed', 'NextButton');
export const InfoButton = createIconButtonComponent('Info', 'InfoButton');
export const CancelButton = createIconButtonComponent('Cancel', 'CancelButton');
export const SignOutIcon = createIconButtonComponent('SignOut', 'SignOutButton');
export const ChangeLanguageButton = createIconButtonComponent('LocaleLanguage', 'ChangeLanguageButton');
