/**
 *  **NOTE**: All the validators must be type safe! DO NOT USE `any` type!
 *  How to write a validator function:
 * 
 * 1. If the validator is specific for an SignalR event, declare the validator in ValidatorFn<"Event"> style:
 *  const entranceDoorEvent: ValidatorFn<"DoorChangedEvent"> = (_, params) =>
 *    params.door === DoorEnum.Entrance;
 * 
 * 2. If the validator is for any SignalR event, declare the validator in expaned ValidatorFn style:
 *  const uniqueAgainst = <T extends EventName>(...eventState: EventState[]): ValidatorFn<T> =>
 *    (ctx) => eventState.every(state => !ctx.isStateHandlerActive(state));
 * 
 * 3. If the validator has nothing to do with default parameters, declare the validator in arrow function style with no generic parameters:
 *  const checkInOutInConfirmingState = () => store.getState().checkInOut.checkInOutState === CheckInOutStateEnum.WaitForConfirmingResults;
 */

import { AppStateEnum, CheckInOutStateEnum } from "../constants/enums/app-state.enum";
import { DoorEnum } from "../constants/enums/signalr.enum";
import { EventState } from "../constants/enums/state.enum";
import { SignalRConstants } from "../constants/signalr.constants";
import { DoorButtonPressedParam, EventName, EventParam } from "../models/signalr.model";
import { store } from "../states/store";
import { StateHandler, ValidatorFn } from "./handlers/common.handler";
import { StateContext } from "./state-context";

const and = <T extends EventName>(...fns: ValidatorFn<T>[]): ValidatorFn<T> =>
  (ctx, params, handler) => fns.every(fn => fn(ctx, params, handler));

const not = <T extends EventName>(fn: ValidatorFn<T>): ValidatorFn<T> => (ctx, params, handler) => !fn(ctx, params, handler);
const or = <T extends EventName>(...fns: ValidatorFn<T>[]): ValidatorFn<T> =>
  (ctx, params, handler) => fns.some(fn => fn(ctx, params, handler));

const any = <T extends EventName>(): ValidatorFn<T> => () => true;

/**
 * Session has started.
 */
const hasSession = (ctx: StateContext) => ctx.session.hasSession;

/**
 * The state handler is not a standalone state, must have been expected.
 */
const mustBeExpected = <T extends EventName>(ctx: StateContext, _params: EventParam<T>, handler: StateHandler<T>) => 
  ctx.expectStates.includes(handler.state);

const requireRoomId = (ctx: StateContext) => !!ctx.roomId;

const roomConfigured = (ctx: StateContext) => ctx.isRoomConfigured;

const twoDoorClosed = (ctx: StateContext) => !ctx.doorSession.isEntranceDoorOpened && !ctx.doorSession.isWarehouseDoorOpened;

const entranceDoorState = <T extends EventName>(isOpened: boolean): ValidatorFn<T> => (ctx) => ctx.doorSession.isEntranceDoorOpened === isOpened;
const warehouseDoorState = <T extends EventName>(isOpened: boolean): ValidatorFn<T> => (ctx) => ctx.doorSession.isWarehouseDoorOpened === isOpened;

const entranceReaderReadEvent: ValidatorFn<"CardReaderRead"> = (_, params) =>
  params.door === DoorEnum.Entrance;

const warehouseReaderReadEvent: ValidatorFn<"CardReaderRead"> = (_, params) =>
  params.door === DoorEnum.Warehouse;

const doorOpenedEvent: ValidatorFn<"DoorChangedEvent"> = (_, params) => params.isOpen;
const doorClosedEvent: ValidatorFn<"DoorChangedEvent"> = (_, params) => !params.isOpen;

const entranceDoorOpenEvent = (isOpened: boolean): ValidatorFn<"DoorChangedEvent"> => (_, params) =>
  params.door === DoorEnum.Entrance && params.isOpen === isOpened;

const warehouseDoorOpenEvent = (isOpened: boolean): ValidatorFn<"DoorChangedEvent"> => (_, params) =>
  params.door === DoorEnum.Warehouse && params.isOpen === isOpened;

const doorButtonEvent: ValidatorFn<DoorButtonPressedParam["event"]> = (_, params) => params.event === SignalRConstants.Events.DoorButtonPressed;

const entranceDoorButtonEvent: ValidatorFn<"DoorButtonPressed"> = (ctx, params, handler) =>
  doorButtonEvent(ctx, params, handler) && params.door === DoorEnum.Entrance

const warehouseDoorButtonEvent: ValidatorFn<"DoorButtonPressed"> = (ctx, params, handler) =>
  doorButtonEvent(ctx, params, handler) && params.door === DoorEnum.Warehouse

/**
 * Scan session hasn't been timedout.
 * 
 * **NOTE**:
 * - When you want to check if current session has started but not in scanning session,
 * combine this validator with `hasSession`.
 */
const scanSessionIsOn = (ctx: StateContext) => ctx.session.isScanning;

/**
 * Only one kind of this handler will be running at a time,
 * so while this handler is active, other events pointing to this state handler will be ignored.
 */
const unique = <T extends EventName>(ctx: StateContext, _: EventParam<T>, handler: StateHandler<T>) => 
  !ctx.isStateHandlerActive(handler.state);

/**
 * Run if other states are not currently active. 
 */
const uniqueAgainst = <T extends EventName>(...eventState: EventState[]): ValidatorFn<T> =>
  (ctx) => eventState.every(state => !ctx.isStateHandlerActive(state));

const notInCardVerifyingState = <T extends EventName>(ctx: StateContext, params: EventParam<T>, handler: StateHandler<T>) =>
  uniqueAgainst<T>(EventState.CardReaderReadEntrance, EventState.CardReaderReadWarehouse)(ctx, params, handler)

const expectNextStates = <T extends EventName>(...states: EventState[]): ValidatorFn<T> => (ctx) =>
  states.every(state => ctx.isStateExpected(state))

const expectNextStatesExact = <T extends EventName>(...states: EventState[]): ValidatorFn<T> => (ctx) =>
  ctx.isStateExpectedExact(states)

/// Out-of-context validators:
const isCheckInFlow = () => {
  const state = store.getState()
  return state.app.appState === AppStateEnum.CheckIn
    && (
      state.checkInOut.checkInOutState === CheckInOutStateEnum.WaitForConfirmingResults
      || state.checkInOut.checkInOutState === CheckInOutStateEnum.WaitForClosingDoors
    )
}

const isCheckOutFlow = () => {
  const state = store.getState()
  return state.app.appState === AppStateEnum.CheckOut
  && (
    state.checkInOut.checkInOutState === CheckInOutStateEnum.WaitForConfirmingResults
    || state.checkInOut.checkInOutState === CheckInOutStateEnum.WaitForClosingDoors
  )
}

export {
  and,
  not,
  or,
  any,
  hasSession,
  mustBeExpected,
  requireRoomId,
  twoDoorClosed,
  entranceDoorState,
  warehouseDoorState,
  entranceReaderReadEvent,
  warehouseReaderReadEvent,
  doorOpenedEvent,
  doorClosedEvent,
  entranceDoorOpenEvent,
  warehouseDoorOpenEvent,
  scanSessionIsOn,
  unique,
  uniqueAgainst,
  notInCardVerifyingState,
  roomConfigured,
  doorButtonEvent,
  entranceDoorButtonEvent,
  warehouseDoorButtonEvent,
  isCheckInFlow,
  isCheckOutFlow,
  expectNextStates,
  expectNextStatesExact,
}
