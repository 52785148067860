import { CommunicationObserver } from "../../App";
import { StateHandlerRunModeEnum } from "../../constants/enums/handler-type.enum";
import { EventState } from "../../constants/enums/state.enum";
import { SignalRConstants } from "../../constants/signalr.constants";
import { handlerlog } from "../../utils/log.utils";
import { and, not, requireRoomId, scanSessionIsOn, doorButtonEvent, hasSession, mustBeExpected, entranceDoorButtonEvent, warehouseDoorButtonEvent, or, isCheckOutFlow, warehouseDoorState } from "../validators";
import { ExpectNextStates, StateHandler } from "./common.handler";

export const DoorButtonPressedBeforeCheckOutScanningSessionHandler: StateHandler<typeof SignalRConstants.Events.DoorButtonPressed> = {
  state: EventState.DoorButtonPressedBeforeCheckOutScanningSession,
  event: SignalRConstants.Events.DoorButtonPressed,
  mode: StateHandlerRunModeEnum.Standalone,
  validator: and(
    requireRoomId,
    mustBeExpected,
    hasSession,
    not(scanSessionIsOn),
    doorButtonEvent,
    isCheckOutFlow, // In check out flow,
    or(
      warehouseDoorButtonEvent,
      and(
        entranceDoorButtonEvent,
        warehouseDoorState(false)
      )
    )
  ),
  async handler(ctx, params) {
    // Users can open any door they want before scanning session.
    // We just have send the lock signal to the other door.

    // In before scanning session, the call to session.end() will just clear data and end current session.
    // It doesn't suppose to open any door.
    ctx.session.end();

    await CommunicationObserver.openDoorInside(params.door);
    await CommunicationObserver.turnOnMagnetLockForOppositeDoor(params.door);

    handlerlog(this.state, `door button pressed before scanning session. Ending session and open door: ${params.door}`);
    
    return ExpectNextStates.Unchanged;
  },
};
