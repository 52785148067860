import { CommunicationObserver, ConfirmTimer } from "../App";
import { ApplicationVersion } from "../constants/application.constants";
import { AppStateEnum } from "../constants/enums/app-state.enum";
import { SessionStateEnum } from "../constants/enums/session-state.enum";
import { SignalRConstants } from "../constants/signalr.constants";
import { EquipmentInfo } from "../models/equipment-info.model";
import { ApiService } from "../services/api.service";
import { LocalStorageService } from "../services/local-storage.service";
import { AppActions } from "../states/app/app.slice";
import { CheckInOutActions } from "../states/app/check-in-out-state.slice";
import { appDispatch, store } from "../states/store";
import { cancelInactivityTimer, startInactivityTimer } from "../utils/common.utils";
import { isEquipmentInvalid, makeCheckInOutUpdateRequestModel } from "../utils/equipment.utils";
import { Result } from "../utils/error-handling.utils";
import { emptyVoidFunction } from "../utils/functional.utils";
import { log } from "../utils/log.utils";
import { Timer } from "../utils/timer.utils";
import { isCheckOutFlow } from "./validators";

export class Session {
  /**
   * Session state.
   */
  private state: SessionStateEnum = SessionStateEnum.Uninit;
  private confirmTimer: Timer = ConfirmTimer;
  private confirmTimeoutInSecond: number = SignalRConstants.Default.ConfirmTimeoutInSecond;
  private idleTimeoutInSecond: number = SignalRConstants.Default.IdleTimeoutInSecond;

  private scannedIds: Set<string> = new Set();
  private onSessionEndFns: VoidFunction[] = [];

  private isForceUpdate: boolean = false;

  get hasSession() {
    return this.state === SessionStateEnum.Opened;
  }

  get rfidScannedIds(): ReadonlySet<string> {
    return this.scannedIds;
  }

  get isScanning() {
    return this.confirmTimer.isRunning;
  }

  get hasNoTimeout() {
    return this.confirmTimer.isRunIndefinitely;
  }

  get idleTimeoutInSeconds() {
    return this.idleTimeoutInSecond;
  }

  constructor() {
    this.reloadSettings();
  }

  reloadSettings() {
    const settings = LocalStorageService.getSettings();

    this.confirmTimeoutInSecond = settings.confirmTimeoutInSecond;
    this.idleTimeoutInSecond = settings.idleTimeoutInSecond;
  }

  start() {
    if (this.state === SessionStateEnum.Opened) {
      return false;
    }

    this.state = SessionStateEnum.Opened;
    return true;
  }

  /**
   * Current session is marked to be forced updating check in/out status.
   */
  async endWithForceUpdate() {
    this.isForceUpdate = true;
    return this.end();
  }

  /**
   * Session.end(): before scanning session will just clear data.
   */
  async end() {
    log(`Session::end: ending session and clearing states...`)

    // IMPORTANT: the session must be closed immediately, everything else can happen later.
    this.state = SessionStateEnum.Closed;

    // Use async for this statement to make sure all the function use current state before it's wiped out.
    await this.confirmTimer.stopTimerAndClearAll();
    this.clearSessionData();
    log(`Session::end: session has ended.`)
  }

  /**
   * Cancel the session and automatically open the door based on what the current flow is (check in or check out).
   */
  async cancel() {
    log(`Session::cancel: cancelling session and clearing states.`);
    
    await CommunicationObserver.rfidReaderStop();

    // IMPORTANT: the session must be closed immediately, everything else can happen later.
    this.state = SessionStateEnum.Closed;

    
    if (isCheckOutFlow()) {
      CommunicationObserver.openWarehouseDoorInside();
    }
    else {
      // Open entrance door by default, just in case when user is stucked in the room
      // and is not in the check in or check out flow.
      CommunicationObserver.openEntranceDoorInside();
    }

    this.confirmTimer.cancel();
    this.clearSessionData();
    // this.checkLatestClientVersion();
  }

  /**
   * Like cancel session except that we don't open any door.
   */
  async emergencyCancel() {
    log(`Session::emergencyCancel: cancelling session and clearing states. Emergency cancel won't open any door!`);

    // IMPORTANT: the session must be closed immediately, everything else can happen later.
    this.state = SessionStateEnum.Closed;

    this.confirmTimer.cancel();
    this.clearSessionData();
    // this.checkLatestClientVersion();
  }

  /**
   * Run when confirm timer is run out.
   * 
   * The request to stop RFID scanner and submit check in/out equipment are already been covered.
   * So we don't have to include it in the callback.
   */
  onConfirmTimedout(fn: VoidFunction) {
    this.confirmTimer.onFinished(fn);
  }

  /**
   * This callback will be called even if the session is cancelled
   * (of course it also will be called when the session is ended successfully).
   */
  onSessionEnd(fn: VoidFunction) {
    this.onSessionEndFns.push(fn);
  }

  async startScanAndRegisterEndSession() {
    log(`Session::startScanAndRegisterEndSession: start scanning. Timeout:`, this.confirmTimeoutInSecond, `s`);

    // Automatically start scanning.
    await CommunicationObserver.rfidReaderStart();

    this.confirmTimer.onTick(remainingSeconds => {
      appDispatch(CheckInOutActions.setRemainingSeconds(remainingSeconds))
    });

    this.confirmTimer.onFinished(() => this.onTimedoutOrCanceled());

    this.confirmTimer.startTimer(this.confirmTimeoutInSecond);

    if (this.confirmTimer.isRunIndefinitely) {
      startInactivityTimer()
    }
  }

  /**
   * Register scanned equipment global ID and restart the timer.
    */
 registerScannedId(id: string) {
    // It's a set, just add the id in.
    // Since we're currently getting equipment info asynchronously, this is probaly useless.
    // I'm going to keep this anyway since it maybe useful in the future.
    this.scannedIds.add(id);

    this.confirmTimer.restart();
  }

  private clearSessionData() {
    this.state = SessionStateEnum.Closed;
    this.scannedIds.clear();

    appDispatch(AppActions.setCardId(undefined));

    try {
      // Who knows if you're a good person >:)
      this.onSessionEndFns.forEach(fn => fn());
      this.onSessionEndFns = [];
    }
    finally {
      // Transition first: switch page and open approriate door.
      appDispatch(AppActions.switchToCheckInOutComplete());

      appDispatch(CheckInOutActions.clearState());

      // Reset the onConfirm callback so we don't accidentally call it.
      appDispatch(CheckInOutActions.setOnConfirmResult(emptyVoidFunction));
    }

    // IMPORANT: reset this value after API submitting.
    this.isForceUpdate = false;
  }

  private async onTimedoutOrCanceled() {
    cancelInactivityTimer()
    log(`Session::onTimedoutOrCanceled: timer has stopped. Stopping the RFID scanner.`);

    // We don't even have to run this, because the method this.submitEquipmentInfo
    // already run *OpenInside SignalR methods.
    await CommunicationObserver.rfidReaderStop();

    log(`Session::onTimedoutOrCanceled: submitting results.`);

    // Note: get everything you need from the Redux state then store it safely in a variable.
    // Be wary that it may use internal mutability.
    const checkInOutState = store.getState().checkInOut;
    const appState = store.getState().app.appState;
    const equipmentInfoList = checkInOutState.equipmentInfo.slice();
    
    await this.submitEquipmentInfo(appState, equipmentInfoList, this.isForceUpdate);

    // The API might take some time to complete, after completion, we clear session data.
    this.clearSessionData();
    // this.checkLatestClientVersion();
  }

  private async submitEquipmentInfo(appState: AppStateEnum, equipmentInfoList: EquipmentInfo[], forceUpdate: boolean = false) {
    const isCheckInOut = appState === AppStateEnum.CheckIn
      || appState === AppStateEnum.CheckOut

    if (!isCheckInOut) {
      log(
        `Session::submitEquipmentInfo: submit function is called but the state is not CheckIn or CheckOut. State:`,
        appState
      );
      return
    } 
    
    const isCheckOut = appState === AppStateEnum.CheckOut;

    const request = makeCheckInOutUpdateRequestModel(equipmentInfoList, isCheckOut, forceUpdate);

    log(
      `Session::submitEquipmentInfo: submitting for: ${appState},`,
      request.length,
      `equipment, data:`,
      request
    );

    const cannotCheckout = isCheckOut && !forceUpdate && equipmentInfoList.some(eq => isEquipmentInvalid(eq, isCheckOut));
    if (cannotCheckout) {
      log(`Session::submitEquipmentInfo: skip submitting due to invalid equipment (CheckOut) => turn off lock warehouse door, list:`,
        equipmentInfoList);
      CommunicationObserver.openWarehouseDoorInside();
      return;
    }

    if (forceUpdate) {
      log(`Session::submitEquipmentInfo: force update equipment info.`);
    }

    CommunicationObserver.openDoorFromInsideAfterState(appState);

    if (!request.length) {
      log(`Session::submitEquipmentInfo: empty equipment info list => skip submitting.`)
      return;
    }

    const result = await Result.fromAPIPromise(ApiService.updateCheckInOutEquipment(request, forceUpdate));

    if (result.isOk) {
      log(`Session::submitEquipmentInfo: submitted successfully!`)
    }
    else {
      log(`Session::submitEquipmentInfo: submit failed, err:`, result.data);
    }
  }

  public async checkLatestClientVersion() {
    const isLatest = await ApiService.checkLatestVersion(ApplicationVersion);
    if(!isLatest){
      setTimeout(()=>{
        window.location.reload();
      }, 1000);
      
    }
  }
}
