import { CommunicationObserver } from "../../App";
import { StateHandlerRunModeEnum } from "../../constants/enums/handler-type.enum";
import { EventState } from "../../constants/enums/state.enum";
import { SignalRConstants } from "../../constants/signalr.constants";
import { and, hasSession, not, requireRoomId, or, warehouseDoorButtonEvent, warehouseDoorState, entranceDoorState, entranceDoorButtonEvent } from "../validators";
import { ExpectNextStates, StateHandler } from "./common.handler";

export const DoorButtonPressedNoSessionHandler: StateHandler<typeof SignalRConstants.Events.DoorButtonPressed> = {
  state: EventState.DoorButtonPressedNoSession,
  event: SignalRConstants.Events.DoorButtonPressed,
  mode: StateHandlerRunModeEnum.Standalone,
  validator: and(
    requireRoomId,
    not(hasSession),
    or(
      and(
        warehouseDoorButtonEvent,
        entranceDoorState(false)
      ),
      and(
        entranceDoorButtonEvent,
        warehouseDoorState(false)
      )
    ),
  ),
  async handler(_, params) {
    // We can only open the door when the opposite door is closed.

    await CommunicationObserver.openDoorInside(params.door);
    await CommunicationObserver.turnOnMagnetLockForOppositeDoor(params.door);
    
    return ExpectNextStates.Unchanged;
  },
};
