import { CommunicationObserver } from '../../App';
import { AppStateEnum } from '../../constants/enums/app-state.enum';
import { EventState } from '../../constants/enums/state.enum';
import { SignalRConstants } from '../../constants/signalr.constants';
import { handlerlog } from '../../utils/log.utils';
import { and, hasSession, mustBeExpected, requireRoomId, warehouseDoorOpenEvent } from '../validators';
import { ExpectNextStates, StateHandler } from './common.handler';

export const WarehouseDoorOpenedForCheckoutHandler: StateHandler<typeof SignalRConstants.Events.DoorChangedEvent> = {
  state: EventState.WarehouseDoorOpenedForCheckout,
  event: SignalRConstants.Events.DoorChangedEvent,
  validator: and(requireRoomId, hasSession, mustBeExpected, warehouseDoorOpenEvent(true)),
  async handler(ctx, params) {
    ctx.doorSession.updateDoorState(params);

    handlerlog(
      this.state,
      `the warehouse door is opened. Swith appState to: ${AppStateEnum.CheckOut}, waiting for both doors to be closed.`
    );

    await CommunicationObserver.warehouseTurnOnMagnetLock();
    
    return ExpectNextStates.WarehouseDoorClosedForCheckOutOrEmergencyOnEntranceDoor;
  },
};
