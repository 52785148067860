import { CommunicationObserver } from "../../App";
import { EventState } from "../../constants/enums/state.enum";
import { SignalRConstants } from "../../constants/signalr.constants";
import { ApiService } from "../../services/api.service";
import { AppActions } from "../../states/app/app.slice";
import { CheckInOutActions } from "../../states/app/check-in-out-state.slice";
import { appDispatch } from "../../states/store";
import { errorlog } from "../../utils/log.utils";
import { and, hasSession, not, notInCardVerifyingState, requireRoomId, warehouseReaderReadEvent } from "../validators";
import { ExpectNextStates, StateHandler } from "./common.handler";
import { toast } from 'react-toastify';

export const CardReaderReadWarehouseHandler: StateHandler<typeof SignalRConstants.Events.CardReaderRead>  = {
    state: EventState.CardReaderReadWarehouse,
    event: SignalRConstants.Events.CardReaderRead,
    validator: and(requireRoomId, not(hasSession), notInCardVerifyingState, warehouseReaderReadEvent),
    async handler(ctx, params) {
        if(!ctx.roomId) {
            errorlog(`handler::${this.state}: roomId is undefined. This should not happen since we have ${requireRoomId.name} validators`);
            return ExpectNextStates.Unchanged;
        }
        appDispatch(AppActions.setCardId(params.chipId));

        const token = await ApiService.getAuthToken(ctx.roomId, params.chipId);
        toast.dismiss();
        ctx.session.start();
        ctx.session.onSessionEnd(() => {
            ctx.updateExpectNextStates(ExpectNextStates.AnyStates);
        });

        appDispatch(CheckInOutActions.setAuthenInfo(token));
        appDispatch(AppActions.setValidIdCardScanned());

        await CommunicationObserver.openWarehouseDoorOutside();

        appDispatch(AppActions.startCheckOut());
        appDispatch(CheckInOutActions.switchToWaitForClosingDoors());

        return ExpectNextStates.WarehouseDoorOpenedForCheckout;
    }
}