import { ButtonHTMLAttributes, DetailedHTMLProps, MouseEventHandler } from "react"
import { css } from "../../utils/css.utils"
import "./index.scss"

interface ButtonProps {
  className?: string,
  label: string | JSX.Element,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  buttonProps?: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  disabled?: boolean,
  readonly?: boolean,
}

export const Button = (props: ButtonProps) => {
  return (
    <button
      className={css(
        "otg-button",
        props.disabled && "disabled readonly",
        props.readonly && "readonly",
        props.className)}
      onClick={props.onClick}
      {...props.buttonProps}
    >
      {props.label}
    </button>
  )
}

const createButtonComponent = (className: string, componentName: string) => {
  const ButtonComponent = (props: ButtonProps) => (
    <Button
      {...props}
      className={css(className, props.className)}
    />
  )

  ButtonComponent.displayName = componentName

  return ButtonComponent
}

export const ConfirmButton = createButtonComponent("otg-confirm-btn", "ConfirmButton")
export const SecondaryButton = createButtonComponent("otg-secondary-btn", "SecondaryButton")
export const CancelButton = createButtonComponent("otg-cancel-btn", "CancelButton")
