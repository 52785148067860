import { ReactNode, useCallback, useState } from 'react';
import { ModalContent } from '../../states/app/app.slice';
import { css } from '../../utils/css.utils';
import { CancelButton } from "../Button/IconButton";
import './index.scss';

export interface IModalProps {
  isOpen: boolean;
  header?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  onDismiss?: (() => void) | undefined;
}

export const Modal = (props: IModalProps) => {
  const {
    header,
    content,
    footer,
    onDismiss
  } = props;

  const onDismissModal = useCallback(() => {
    if(onDismiss) {
      onDismiss();
    }
  }, [onDismiss]);
  
  return (
    <div className={css("otg-modal", props.isOpen && "show")}>

      <div className="otg-modal-container">
        {onDismiss && <span className="otg-close" onClick={onDismissModal}><CancelButton /></span>}
        <h2 className="otg-modal-header">{header}</h2>
        <div className="otg-modal-content">
          {content}
        </div>
        <div className="otg-modal-footer">
          {footer}
        </div>
      </div>
    </div>
  )
}