import { CheckOutStatusEnum } from "../constants/enums/check-out-status.enum"
import { EquipmentInfo } from "../models/equipment-info.model"
import { EquipmentCheckInOutItemModel } from "../models/equipment-check-inout.model"
import { DueDateStatusesEnum } from "../constants/enums/due-date-statuses.enum"
import { EquipmentActiveStatusEnum } from "../constants/enums/active-status.enum"
import { store } from "../states/store"

export const isEquipmentInvalid = (eqInfo: EquipmentInfo, isCheckout: boolean) =>
  (isCheckout && !eqInfo.isValidToCheckOut && !eqInfo.isNotFound)

export const isEquipmentInvalidInCheckoutFlow = (eqInfo: EquipmentInfo) => isEquipmentInvalid(eqInfo, true)

export const isEquipmentNotFound = (eqInfo: EquipmentInfo) => eqInfo.isNotFound || eqInfo.equipmentId === 0

export const isEquipmentInvalidOrNotFound = (eqInfo: EquipmentInfo, isCheckout: boolean) =>
  isEquipmentInvalid(eqInfo, isCheckout) || isEquipmentNotFound(eqInfo)

export const makeCheckInOutUpdateRequestModel = (eqInfoList: EquipmentInfo[], isCheckOut: boolean, isForceUpdate: boolean = false): EquipmentCheckInOutItemModel[] => {
  return eqInfoList
    .filter(eq =>
      isForceUpdate
        ? !isEquipmentNotFound(eq)
        : !isEquipmentInvalidOrNotFound(eq, isCheckOut)
    )
    .map(eq => ({
      equipmentId: eq.equipmentId,
      checkOutStatus: getEquipmentCheckOutStatus(eq, isCheckOut),
    }))
}

export const hasInvalidEquipmentInCheckoutFlow = () => {
  const equipmentList = store.getState().checkInOut.equipmentInfo;
  return equipmentList.some(eq => isEquipmentInvalidInCheckoutFlow(eq));
} 

// We only care about 3 states:
// - CheckIn
// - CheckOut
// - ReturnControl (when it's check in and isNeedServiceUserStatus is true)
const getEquipmentCheckOutStatus = (eqInfo: EquipmentInfo, isCheckOut: boolean): CheckOutStatusEnum =>
  isCheckOut
  ? CheckOutStatusEnum.CheckOut
  : eqInfo.isNeedService
  ? CheckOutStatusEnum.ReturnControl
  : CheckOutStatusEnum.CheckIn

export const getTranslationActiveStatus = (status: EquipmentActiveStatusEnum) => {
  // Switch case ensure we don't forget to handle when adding new enum key.
  switch (status) {
    case EquipmentActiveStatusEnum.Active:
      return "CaptionResource.EquipmentActiveStatus.1"

    case EquipmentActiveStatusEnum.Inactive:
      return "CaptionResource.EquipmentActiveStatus.2"
      
    case EquipmentActiveStatusEnum.Discarded:
      return "CaptionResource.EquipmentActiveStatus.3"
        
    case EquipmentActiveStatusEnum.Missing:
      return "CaptionResource.EquipmentActiveStatus.4"
  }
}


export const getTranslationDueStatus = (status: DueDateStatusesEnum) => {
  switch (status) {
    case DueDateStatusesEnum.Good:
      return "Equipment.Good";
    case DueDateStatusesEnum.Alert:
      return "Equipment.CloseToDueDate";
    case DueDateStatusesEnum.Bad:
      return "Equipment.Overdue";
    case DueDateStatusesEnum.None:
      return "Settings.NoDueDate";        
  }
}

export const getNextControlDueIcon = (status: number): string => {
  if (status !== DueDateStatusesEnum.None) {
    return `oi-jc-legal due-status-${status}`;
  }

  return '';
}