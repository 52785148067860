import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { App } from "./App"
import { store } from "./states/store"
import { initializeIcons } from "@fluentui/font-icons-mdl2"
import configureTranslation from "./utils/language.utils"
import "./assets/css/_global.scss"
import { initDebug } from "./utils/debug.utils"

configureTranslation().then(() => {
  initializeIcons()
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  )
})

initDebug()
