import { Language, SupportedLanguageConstants } from '@onix/common';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useForceRender } from '../../hooks/useForceRender';
import { ApiService } from '../../services/api.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { css } from '../../utils/css.utils';
import { log } from '../../utils/log.utils';
import { getGlobalStateContext } from '../../utils/signalr.utils';
import { CancelIcon, CheckMarkIcon } from '../Icon/Icon';
import { Spinner } from '../Spinner/Spinner';
import './index.scss';

export type ChangeLanguageDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const ChangeLanguageDialog: React.FC<ChangeLanguageDialogProps> = ({ open, onClose }) => {
  const [t] = useTranslation();

  const forceRender = useForceRender();

  const [isLoading, setIsLoading] = useState(false);
  const [currentLanguageId, setCurrentLanguageId] = useState(LocalStorageService.getCurrentLanguageId());

  const onChangeLanguage = async (lang: Language) => {
    const ctx = getGlobalStateContext();
    setCurrentLanguageId(lang.id);

    if (!ctx.roomId || isLoading) {
      return;
    }

    setIsLoading(true);

    const updateResult = await ApiService.updateLanguage(ctx.roomId, lang.id);

    log(
      `ChangeLanguageDialog: Update language ${updateResult ? 'successfully' : 'failed'}! (RoomId: ${ctx.roomId}, LanguageId: ${lang.id})`
    );

    LocalStorageService.setCurrentLanguageId(lang.id);

    try {
      const settings = await ApiService.getRoomSetting(ctx.roomId);

      // Like the old code.
      settings.logEnabled = true;

      ctx.updateRoomSettings(settings);
    } catch (err) {
      log(`ChangeLanguageDialog: Error while getting room settings! (RoomId: ${ctx.roomId})`, err);
    }

    forceRender();

    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    setCurrentLanguageId(LocalStorageService.getCurrentLanguageId());
  }, [open]);

  return createPortal(
    <div className={css('change-language-dialog', open && 'open')}>
      <div className={css('change-language-container', isLoading && 'pointer-events-none loading')}>
        <div className="header">
          <div className="text">{t('CaptionResource.ChangeLanguage')}</div>

          <div className="close-btn">
            <CancelIcon onClick={() => !isLoading && onClose()} />
          </div>
        </div>
        <div className="list">
          {SupportedLanguageConstants.map((lang) => (
            <div
              key={lang.code}
              className={css('item', currentLanguageId === lang.id && 'selected')}
              onClick={() => onChangeLanguage(lang)}
            >
              <div className={css('checkmark')}>
                <CheckMarkIcon />
              </div>
              {lang.name}
            </div>
          ))}
        </div>
      </div>
      <div className={css('spinner', isLoading && 'show')}>
        <Spinner />
      </div>
      {open && <style>{` #root { pointer-events: none; } `}</style>}
    </div>,
    document.body
  );
};
