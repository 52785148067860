
import { Key } from "react"
import { css } from "../../utils/css.utils"
import "./index.scss"

export type StepItem<T> = {
  key: T,
  title?: string,
}

export type StepItemDisplay<T> = StepItem<T> & {
  idx: number,
  stepped: boolean,
}

export type StepperRef = {
  nextStep: VoidFunction,
}

export interface StepperProps<T> {
  activeStep: T,
  steps: StepItem<T>[],
  step?: (target: StepItem<T>, list: StepItem<T>[]) => void,

  allowStepBack?: boolean,
  allowSkip?: boolean,
  disabled?: boolean,
}

export const Stepper = <T,>(props: StepperProps<T>) => {
  const activeStepKey = props.activeStep
  const activeStepIdx = props.steps.findIndex(s => s.key === props.activeStep)

  const canStepInto = (targetIdx: number) => !props.disabled && targetIdx < props.steps.length
    && (
      (props.allowStepBack && targetIdx < activeStepIdx)
      || (props.allowSkip && targetIdx > activeStepIdx)
    )

  const step = (target: StepItem<T>, targetIdx: number) => {
    if (!canStepInto(targetIdx)) {
      return
    }

    props.step?.(target, props.steps)
  }

  return (
    <div className={css("otg-stepper", props.disabled && "disabled")}>
      <ul>
        {props.steps.map((item, idx) => (
          <li
            key={item.key as Key}
            className={css(
              item.key === activeStepKey && "active",
              idx < activeStepIdx && "stepped",
              canStepInto(idx) && "clickable",
            )}
            onClick={() => step(item, idx)}
          >
            {item.title || idx + 1}
          </li>
        ))}
      </ul>
    </div>
  )
}
