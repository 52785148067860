import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux";
import { AppStateEnum } from "../../constants/enums/app-state.enum";
import { appStateSelector, isInvalidCardIdScannedSelector } from "../../states/app/app.slice";
import { InfoScreen } from "../InfoScreen/InfoScreen";
import { Spinner } from "../Spinner/Spinner";

export const CardReaderRead = () => {
  const [t] = useTranslation();
  const isInvalidCardIdScanned = useSelector(isInvalidCardIdScannedSelector);
  const appState = useSelector(appStateSelector);

  const isReaderCheckIn = appState === AppStateEnum.CardReaderReadCheckIn;
  const title = isReaderCheckIn ? t("AppInspect.ToolGateCheckIn") : t("AppInspect.ToolGateCheckOut");

  if (isInvalidCardIdScanned) {
    return (
      <InfoScreen
        className="card-reader-read"
        header={title}
        message={t("AppInspect.ToolGateInvalidIdMessage")}
        icon={<Spinner />}
      />
    )
  }

  return <></>;
}