import { ReactNode } from "react"
import "./index.scss"

interface MainLayoutProps {
  children?: ReactNode
}

export const MainLayout = (props: MainLayoutProps) => {
  return (
    <div className="otg-main-layout">
      {props.children}
    </div>
  )
}
