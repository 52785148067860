import { StateHandlerRunModeEnum } from "../../constants/enums/handler-type.enum";
import { EventState } from "../../constants/enums/state.enum";
import { SignalRConstants } from "../../constants/signalr.constants";
import { handlerlog } from "../../utils/log.utils";
import { and, hasSession, isCheckInFlow, roomConfigured, scanSessionIsOn, warehouseDoorButtonEvent } from "../validators";
import { ExpectNextStates, StateHandler } from "./common.handler";

export const WarehouseDoorButtonPressedInCheckInSessionHandler: StateHandler<typeof SignalRConstants.Events.DoorButtonPressed> = {
  state: EventState.WarehouseDoorButtonPressedInCheckInSession,
  event: SignalRConstants.Events.DoorButtonPressed,
  mode: StateHandlerRunModeEnum.Standalone,
  validator: and(
    roomConfigured,
    hasSession,
    scanSessionIsOn,            // Both doors are closed!
    warehouseDoorButtonEvent,   // Only accept the warehouse button (btw, we don't have to handle entrance door for check in flow).
    isCheckInFlow,              // Check in flow only!
  ),
  async handler(ctx, params) {
    handlerlog(this.state, `${params.door} door button has been pressed while checking in. Submit and end session.`);

    // Always valid in check in, so we just submit and end the session.
    await ctx.session.end();

    // This will be overrided anyway by the ctx.onSessionEnd() callback.
    return ExpectNextStates.AnyStates;
  },
};
