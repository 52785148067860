import { CommunicationObserver } from "../../App";
import { StateHandlerRunModeEnum } from "../../constants/enums/handler-type.enum";
import { EventState } from '../../constants/enums/state.enum';
import { SignalRConstants } from '../../constants/signalr.constants';
import { handlerlog } from '../../utils/log.utils';
import { and, hasSession, requireRoomId, isCheckInFlow, scanSessionIsOn, warehouseDoorOpenEvent } from '../validators';
import { ExpectNextStates, StateHandler } from './common.handler';

export const EmergencyWarehouseDoorCheckInSessionHandler: StateHandler<typeof SignalRConstants.Events.DoorChangedEvent> = {
  state: EventState.EmergencyWarehouseDoorCheckInSession,
  event: SignalRConstants.Events.DoorChangedEvent,
  mode: StateHandlerRunModeEnum.Standalone,
  validator: and(
    requireRoomId,
    hasSession,
    isCheckInFlow,                // In check out flow
    scanSessionIsOn,              // and scan session is going on,
    warehouseDoorOpenEvent(true), // suddenly, the warehouse door is opened, then it's an emergency exit.
  ),
  async handler(ctx, params) {
    ctx.doorSession.updateDoorState(params);
    
    handlerlog(this.state,
      `[EMERGENCY/CheckIn] the warehouse door is opened, force updating equipment...`
    );

    ctx.session.endWithForceUpdate();
    CommunicationObserver.resetToStandBy();

    return ExpectNextStates.AnyStates;
  },
};
