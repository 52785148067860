import { ModalContent, AppActions } from "../states/app/app.slice";
import { appDispatch, store } from "../states/store";
import { GlobalErrorAppEnum } from "./enums/global-error.enum";

type GlobalErrorContent = {
  headerKey: ModalContent["header"],
  messageKey: ModalContent["message"],
  isTranslate: boolean,
  onDissmiss: (() => void) | undefined,
}

const getHeaderApiErr = () => store.getState().app.apiErrorModal.header;
const getMessageApiErr = () => store.getState().app.apiErrorModal.message;

const create = (
  headerKey: ModalContent["header"],
  messageKey: ModalContent["message"],
  isTranslate: boolean,
  onDissmiss: (() => void) | undefined): GlobalErrorContent => {
   return {
     headerKey,
     messageKey,
     isTranslate,
     onDissmiss
   };
};

export const getGlobalErrorContent = (val: GlobalErrorAppEnum): GlobalErrorContent | undefined => {

  const globalErrorContent = new Map<GlobalErrorAppEnum, GlobalErrorContent>([
    [GlobalErrorAppEnum.None, create(undefined, undefined, false, undefined)],
    [GlobalErrorAppEnum.UnknownError, 
        create(getHeaderApiErr(), getMessageApiErr(),false, 
        () => {
          appDispatch(AppActions.hideApiErrorModal())
    })],
    [GlobalErrorAppEnum.DisconnectedSignalR, create("AppInspect.ServerDisconnected", "AppInspect.plsWaitWhileReconnecting", true, undefined)],
    [GlobalErrorAppEnum.ClientAppIsOffline, create("CommonResource.Offline", "AppInspect.OfflineMessage", true, undefined)],
    [GlobalErrorAppEnum.ApiIsOffline, create("AppInspect.ServerDisconnected", "AppInspect.plsWaitWhileReconnecting", true, undefined)],
  ])

  return globalErrorContent.get(val);
}