import { StateHandlerRunModeEnum } from "../../constants/enums/handler-type.enum";
import { EventState } from "../../constants/enums/state.enum";
import { EventName, EventParam } from "../../models/signalr.model";
import { StateContext } from "../state-context";
import { StandaloneHandlerFn, StateHandlerFn, StateHandlerResult } from "../state-handler-observer";

/**
 * Predefined expect next states to prevent garbages.
 */
export const ExpectNextStates = {
  /** Expects any incomming states. */
  AnyStates: [] as StateHandlerResult,

  /** When we don't know what to change into, just return `Unchanged`. */
  Unchanged: null,

  /** After: EventState.CardReaderReadEntrance */
  EntranceDoorOpenedForCheckin: [
    EventState.EntranceDoorOpenedForCheckin,
    EventState.EntranceDoorClosedForCheckin,
    EventState.DoorButtonPressedBeforeCheckInScanningSession,
  ], // Entrance door open is optional,
  
  /** After: EventState.EntranceDoorOpenedForCheckin */
  EntranceDoorClosedForCheckInOrEmergencyOnWarehouseDoor: [
    EventState.EntranceDoorClosedForCheckin,
    EventState.EmergencyWarehouseDoorBeforeScanningCheckInSession,
    EventState.DoorButtonPressedBeforeCheckInScanningSession,
  ],
  EntranceDoorClosedForCheckOutOrEmergencyOnWarehouseDoor: [
    EventState.EntranceDoorClosedForCheckout,
    EventState.EmergencyWarehouseDoorBeforeScanningCheckOutSession,
    EventState.DoorButtonPressedBeforeCheckInScanningSession,
  ],
  EntranceDoorClosedForCheckin: [EventState.EntranceDoorClosedForCheckin],
  WarehouseDoorClosedForCheckInOrEmergencyOnEntranceDoor: [
    EventState.WarehouseDoorClosedForCheckin,
    EventState.EmergencyEntranceDoorBeforeScanningCheckInSession,
    EventState.DoorButtonPressedBeforeCheckOutScanningSession,
  ],

  RfidReaderRead: [EventState.RfidReaderRead],

  /// Checkout flow.

  WarehouseDoorOpenedForCheckout: [
    EventState.WarehouseDoorOpenedForCheckout,
    EventState.WarehouseDoorClosedForCheckout,
    EventState.DoorButtonPressedBeforeCheckOutScanningSession,
  ],
  WarehouseDoorClosedForCheckOutOrEmergencyOnEntranceDoor: [
    EventState.WarehouseDoorClosedForCheckout,
    EventState.EmergencyEntranceDoorBeforeScanningCheckOutSession,
    EventState.DoorButtonPressedBeforeCheckOutScanningSession,
  ],
  BothDoorsClosedForCheckout: [EventState.EntranceDoorClosedForCheckout, EventState.WarehouseDoorClosedForCheckout],
  WarehouseDoorClosedForCheckout: [EventState.WarehouseDoorClosedForCheckout],
};

/**
 * Function as validator.
 */
export type ValidatorFn<T extends EventName> = (context: StateContext, params: EventParam<T>, handler: StateHandler<T>) => boolean;
export type ValidatorFnReturn = ReturnType<ValidatorFn<any>>

export type StateHandler<T extends EventName> = {
  /**
   * An event state that belongs to the SignalR event `.event`.
   */
  state: EventState,

  /**
   * A SignalR event type.
   */
  event: T,

  /**
   * Run mode.
   */
  mode?: StateHandlerRunModeEnum,

  /**
   * Describe the requirement to run this handler.
   */
  validator: ValidatorFn<T>,

  /**
   * Actual handler execution code.
   */
  handler: StateHandlerFn<T>,
};

export type StandaloneHandler<T extends EventName> = {
  events: T[],

  /** TODO(knta): defer for this handler */
  handler: StandaloneHandlerFn<T>,
}
