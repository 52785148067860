import { ApiResponseError } from "../models/api-response.model"
import { StatusParam } from "../models/signalr.model"
import { StateContext, StateHandlerActiveId } from "./state-context"
import { StateHandlerResult } from "./state-handler-observer"

export type CurrentStateHandlerContextPrivateObject = {
  exception: ApiResponseError | undefined,
}

/**
 * This class exposes public fields and methods from StateContext.
 */
export class CurrentStateHandlerContext {
  get activeId() {
    return this.stateHandlerActiveId
  }

  get expectStates() {
    return this.ctx.expectStates
  }

  get roomId() {
    return this.ctx.roomId
  }

  get roomName() {
    return this.ctx.roomName
  }

  get session() {
    return this.ctx.session
  }

  get doorSession() {
    return this.ctx.doorSession
  }

  get threwException() {
    return this.data.exception
  }

  constructor(
    private ctx: StateContext,
    private stateHandlerActiveId: StateHandlerActiveId,
    private data: CurrentStateHandlerContextPrivateObject,
  ) {}

  /**
   * Register function to run when the state handler is finished.
   */
  defer(fn: VoidFunction) {
    this.ctx.setStateDeferFn(this.stateHandlerActiveId, fn)
  }

  /**
   * Update room info.
   */
  updateRoomInfoFromStatus(signalRStatusParam: StatusParam['status']) {
    this.ctx.updateRoomInfoFromStatus(signalRStatusParam)
  }

  /**
   * **UNSAFE**: unsafely set expect next state.
   */
  updateExpectNextStates(state: StateHandlerResult) {
    this.ctx.updateExpectNextStates(state)
  }

  updateRoomConfigured(state: boolean) {
    this.ctx.setRoomConfigured(state);
  }
}
