import { useRef, useState } from "react"

export type UseAsyncStateProps<T> = {
  loading?: VoidFunction,
  loaded?: (item: T) => void,
  catch?: (err: any) => void,
  finally?: VoidFunction,
}

export type UseAsyncStateReturn<T> = [T, boolean, (item: Promise<T>) => void, (item: T) => void]

export const useAsyncState = <T,>(initValue: T | (() => T), props?: UseAsyncStateProps<T>): UseAsyncStateReturn<T> => {
  const [state, setState] = useState(initValue)
  const [isLoading, setIsLoading] = useState(false)
  const dominancePromiseId = useRef(0)
  
  const setStateAsync = (asyncState: Promise<T>) => {
    // No cache for the id, increase the dominancePromiseId to invalidate all the promise before.
    dominancePromiseId.current += 1
    const currentPromiseId = dominancePromiseId.current

    setIsLoading(true)
    props?.loading?.()

    type Fn<U, R> = (item: U) => R
    // Wrapper to wrap function f to make sure it only run when current promise is the latest promise.
    const shouldRun = <U, R>(f?: Fn<U, R>): Fn<U, R | undefined> =>
      item => currentPromiseId === dominancePromiseId.current && f ? f(item) : undefined

    const shouldRunVoidFn = (f?: VoidFunction): VoidFunction =>
      () => void (currentPromiseId === dominancePromiseId.current && f?.())

    asyncState
      .then(shouldRun(item => {
        setState(item)
        props?.loaded?.(item)
      }))
      .catch(shouldRun(props?.catch))
      .finally(shouldRunVoidFn(() => setIsLoading(false)))
      .finally(shouldRunVoidFn(props?.finally))
  }

  const setStateSync = (item: T) => {
    
    setState(item)
    setIsLoading(isLoading => {
      if (isLoading) {
        // Invalidate all running promises.
        dominancePromiseId.current += 1
      }

      return false
    })
  }

  return [state, isLoading, setStateAsync, setStateSync]
}
