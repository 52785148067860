import { format } from "date-fns";
import { InactivityTimer, StateHandlerObserver } from "../App";
import { TimerConstants } from "../constants/timer.constants";
import { appDispatch } from "../states/store";
import { AppActions } from "../states/app/app.slice";

export const formatDate = (date: Date): string => format(date, "yyyy.MM.dd HH:mm:ss.SSS")

export const now = () => formatDate(new Date())

export const startInactivityTimer = () => {
  appDispatch(AppActions.showInactivePopup(false))

  InactivityTimer.onFinished(() => {
    appDispatch(AppActions.showInactivePopup(true))
  })

  InactivityTimer.startTimer(TimerConstants.InactivityTimeoutInSecond)
}

export const cancelInactivityTimer = () => {
  InactivityTimer.cancel()
}

export const getContext = () => StateHandlerObserver.context
