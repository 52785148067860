import { CommunicationObserver } from '../../App';
import { DoorEnum } from '../../constants/enums/signalr.enum';
import { EventState } from '../../constants/enums/state.enum';
import { SignalRConstants } from '../../constants/signalr.constants';
import { ApiService } from '../../services/api.service';
import { AppActions } from '../../states/app/app.slice';
import { CheckInOutActions } from '../../states/app/check-in-out-state.slice';
import { appDispatch } from '../../states/store';
import { and, not, notInCardVerifyingState, requireRoomId, roomConfigured, warehouseReaderReadEvent } from '../validators';
import { ExpectNextStates, StateHandler } from './common.handler';

export const RoomConfigurateCardReaderReadWarehouseHandler: StateHandler<typeof SignalRConstants.Events.CardReaderRead> = {
  state: EventState.RoomConfigurateCardReaderReadWarehouse,
  event: SignalRConstants.Events.CardReaderRead,
  validator: and(not(roomConfigured), requireRoomId, notInCardVerifyingState, warehouseReaderReadEvent),
  async handler(ctx, params) {
    const privileges = await ApiService.getCompaniesWithPrivilege(params.chipId);
    if (privileges && privileges.length > 0) {
      if (privileges.some((x) => x.toolStorePrivilege)) {
        appDispatch(AppActions.setCardId(params.chipId));

        ctx.session.start();

        appDispatch(
          CheckInOutActions.setOnConfirmResult(() => {
            ctx.updateRoomConfigured(true);
            ctx.session.end();
            ctx.updateExpectNextStates(ExpectNextStates.AnyStates);
            CommunicationObserver.openWarehouseDoorInside();
            appDispatch(AppActions.switchToCheckInOutComplete());
          })
        );

        appDispatch(AppActions.startSetup());
      }
      await CommunicationObserver.openDoorInside(DoorEnum.Warehouse);

      return ExpectNextStates.AnyStates;
    }
    return ExpectNextStates.Unchanged;
  },
};
