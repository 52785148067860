import { StateHandlerObserver } from "../App";
import { DoorEnum } from "../constants/enums/signalr.enum"
import { SignalRConstants } from "../constants/signalr.constants";
import { EventName, EventParam, SignalRRequest } from "../models/signalr.model";
import { StateContext } from "../signalr/state-context";

const parseDoorEnum = (door: string): DoorEnum | undefined => (DoorEnum as any)[door];

/**
 * Check if we should start standby timer or not:
 *  - The room must be configured.
 *  - The request must be DoorOpenRequest.
 * @param request request to SignalR server.
 * @returns boolean - should start standby timer or not.
 */
const shouldStartStandbyTimerBySignalRRequest = (request: SignalRRequest): boolean =>
  StateHandlerObserver.context.isRoomConfigured
   && request === SignalRConstants.RequestEvents.DoorOpenRequest

const shouldStartStandbyTimerBySignalRIncomingEvent = <T extends EventName>(params: EventParam<T>): boolean =>
  StateHandlerObserver.context.isRoomConfigured
  && (params.event === SignalRConstants.Events.DoorChangedEvent && params.isOpen)

const getGlobalStateContext = (): StateContext => StateHandlerObserver.context

export {
  parseDoorEnum,
  shouldStartStandbyTimerBySignalRRequest,
  shouldStartStandbyTimerBySignalRIncomingEvent,
  getGlobalStateContext,
}
