
export enum AppStateEnum {
  Setup = "Setup",
  SetupSuccessful = "SetupSuccessful",
  CheckInOutComplete = "CheckInOutComplete",
  SignalRReconnected = "SignalRReconnected",
  CheckIn = "CheckIn",
  CheckOut = "CheckOut",
  CardReaderReadCheckIn = "CardReaderReadCheckIn",
  CardReaderReadCheckOut = "CardReaderReadCheckOut",
  RoomNotConfigured = "RoomNotConfigured",
  NoLicense = "NoLicense"
}

export enum CheckInOutStateEnum {
  Uninit = "Uninit",
  WaitForClosingDoors = "WaitForClosingDoors",
  WaitForConfirmingResults = "WaitForConfirmingResults",
  ScanningComplete = "ScanningComplete",
}