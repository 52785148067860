import { DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useRef, useState } from "react"
import { css } from "../../utils/css.utils"
import { log } from "../../utils/log.utils"
import "./index.scss"

interface AutoScrollProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onUserInteraction?: VoidFunction,
  disableAutoScroll?: boolean,
  children: ReactNode[],
}

/**
 *  Around the last pixels near the bottom will be consider bottom and will be auto-scrolling.
 */
const DELTA_BOTTOM_IN_PIXELS = 100 // pixels

/**
 * If the container contains border, padding or margin, this might break the check!
 * 
 * Border or padding should be fine, but please check them if this function does not work in your case.
 */
const isElementScrolledToBottom = (el: HTMLDivElement | null): boolean => {
  return !el || el.offsetHeight + el.scrollTop >= el.scrollHeight - DELTA_BOTTOM_IN_PIXELS
}

const scrollToBottom = (el: HTMLDivElement | null) => {
  if (el) {
    el.scrollTop = el.scrollHeight
  }
}

export const AutoScrollDiv = (props: AutoScrollProps) => {
  const {
    onUserInteraction,
    disableAutoScroll,
    ...divProps
  } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true)

  // Run everytime component rerendered.
  useEffect(() => {
    if (shouldAutoScroll && !disableAutoScroll) {
      scrollToBottom(containerRef.current)
    }
  })

  const updateShouldAutoScroll = (isTrusted: boolean) => {
    if (!isTrusted) {
      return
    }

    const isOnBottom = isElementScrolledToBottom(containerRef.current)

    setShouldAutoScroll(prev => {
      if (prev !== isOnBottom) {
        log(`AutoScrollDiv: should auto scroll:`, isOnBottom)
      }

      return isOnBottom
    })

    onUserInteraction?.()
  }

  const onWheel: AutoScrollProps["onWheel"] = (event) => {
    updateShouldAutoScroll(event.isTrusted)
    props.onWheel?.(event)
  }

  const onTouchMove: AutoScrollProps["onTouchMove"] = (event) => {
    updateShouldAutoScroll(event.isTrusted)
    props.onTouchMove?.(event)
  };

  const onClick: AutoScrollProps["onClick"] = (event) => {
    props.onClick?.(event)
    onUserInteraction?.()
  }

  return (
    <div
      {...divProps}
      ref={containerRef}
      className={css(
        "otg-autoscroll",
        props.className,
      )}
      // onScroll={onScroll}
      onWheel={onWheel}
      onTouchMove={onTouchMove}
      onClick={onClick}
    >
      {props.children}
    </div>
  )
}
