import { LanguageHelper, SupportedLanguageConstants } from '@onix/common';
import i18n from 'i18next';
import * as da from '../assets/i18n/da.json';
import * as de from '../assets/i18n/de.json';
import * as en from '../assets/i18n/en.json';
import * as es from '../assets/i18n/es.json';
import * as no from '../assets/i18n/no.json';
import * as pt from '../assets/i18n/pt.json';
import * as sv from '../assets/i18n/sv.json';

const LanguageIdToLanguageShort = Object.fromEntries(SupportedLanguageConstants.map((a) => [a.id, a.code]));

export const LanguageName = SupportedLanguageConstants.map((m) => {
  return {
    id: m.id,
    description: m.name,
  };
});

const parseLanguageIdToLanguageShort = LanguageHelper.getLanguageCode;

export { LanguageIdToLanguageShort, da, de, en, es, i18n, no, parseLanguageIdToLanguageShort, pt, sv };
