import { EventState } from '../constants/enums/state.enum';
import { LocalStorageService } from '../services/local-storage.service';

const log = (message: string, ...args: any[]): void => void (
  LocalStorageService.shouldLog() &&
  console.log.apply(this, [`%cLOG%c ${message}`, 'border-radius: 6px; border: 2px solid #5F9DF7; padding: 2px 5px;', 'all: unset;', ...args])
);

const errorlog = (message: string, ...args: any[]): void => void (
  LocalStorageService.shouldLog() &&
  console.log.apply(this, [`%cERR%c ${message}`, 'border-radius: 6px; border: 2px solid lightcoral; padding: 2px 5px;', 'all: unset;', ...args])
);

const eventlog = (message: string, ...args: any[]): void => void (
  LocalStorageService.shouldLog() &&
  console.log.apply(this, [`%cEVENT%c ${message}`, 'border-radius: 6px; border: 2px solid hotpink; padding: 2px 5px;', 'all: unset;', ...args])
);

const handlerlog = (handler: EventState, ...args: any[]): void => void (
  LocalStorageService.shouldLog() &&
  console.log.apply(this, [
    `%cHANDLER%c::%c${handler}%c::`,
    `border-radius: 6px; border: 2px solid #04AA6D; padding: 2px 5px;`,
    `all: unset;`,
    `font-weight: bold; color: hotpink;`,
    `all: unset;`,
    ...args])
);

export { log, errorlog, eventlog, handlerlog };
