import { CommunicationObserver } from '../../App';
import { EventState } from '../../constants/enums/state.enum';
import { SignalRConstants } from '../../constants/signalr.constants';
import { CheckInOutActions } from '../../states/app/check-in-out-state.slice';
import { appDispatch } from '../../states/store';
import { entranceDoorOpenEvent, and, hasSession, mustBeExpected, requireRoomId } from '../validators';
import { ExpectNextStates, StateHandler } from './common.handler';

export const EntranceDoorClosedForCheckoutHandler: StateHandler<typeof SignalRConstants.Events.DoorChangedEvent> = {
  state: EventState.EntranceDoorClosedForCheckout,
  event: SignalRConstants.Events.DoorChangedEvent,
  validator: and(requireRoomId, hasSession, mustBeExpected, entranceDoorOpenEvent(false)),
  async handler(ctx, params) {
    ctx.doorSession.updateDoorState(params);

    // TODO(knta): This is unlikely to happen! Come up with something understandable.
    if (ctx.doorSession.isWarehouseDoorOpened) {
      return ExpectNextStates.WarehouseDoorClosedForCheckout;
    }

    await CommunicationObserver.rfidReaderStart();

    await ctx.session.startScanAndRegisterEndSession();

    appDispatch(CheckInOutActions.switchToWaitForConfirmingResults());

    return ExpectNextStates.RfidReaderRead;
  },
};
