import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppStateEnum } from "../../constants/enums/app-state.enum";
import { RoomInfo } from "../../models/redux.model";
import { RootState } from '../store';

export interface AppState {
  isLoading: boolean,
  isSignalRServerReconnected: boolean,
  isSignalRServerConnected: boolean,
  isApiOnline: boolean,
  appState: AppStateEnum,
  roomId: string | undefined,
  roomName: string,
  isInvalidCardIdScanned: boolean,
  apiErrorModal: ModalContent,
  cardId: string| undefined,
  showInactivePopup: boolean
}

export interface ModalContent {
  isShow: boolean,
  header: string | undefined | JSX.Element,
  message: string | undefined | JSX.Element,
}

const intialApiError: ModalContent = {
  isShow: false,
  header: "CaptionResource.CAPTION_ERROR",
  message: undefined
}

const initialState: AppState = {
  isLoading: false,
  isSignalRServerConnected: true,
  isSignalRServerReconnected: false,
  isApiOnline: true,
  appState: AppStateEnum.CheckInOutComplete,
  roomId: undefined,
  cardId: undefined,
  roomName: "",
  isInvalidCardIdScanned: true,
  apiErrorModal: intialApiError,
  showInactivePopup: false,
};

const AppSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    showLoading(state) {
      state.isLoading = true;
    },
    hideLoading(state) {
      state.isLoading = false;
    },
    setSignalRConnectionState(state, { payload }: PayloadAction<boolean>) {
      const oldConnectState = state.isSignalRServerConnected;

      const isReConnected = payload !== oldConnectState && !oldConnectState;

      state.isSignalRServerConnected = payload;

      if (isReConnected) {
        state.appState = AppStateEnum.SignalRReconnected;
      }
    },
    setApiOnlineState(state, { payload }: PayloadAction<boolean>) {
      state.isApiOnline = payload;
    },
    startCheckIn(state) {
      state.appState = AppStateEnum.CheckIn;
    },
    startCheckOut(state) {
      state.appState = AppStateEnum.CheckOut;
    },
    startSetup(state) {
      state.appState = AppStateEnum.Setup;
    },
    startSetupSuccessful(state) {
      state.appState = AppStateEnum.SetupSuccessful;
    },
    setRoomInfo(state, { payload }: PayloadAction<RoomInfo>) {
      state.roomId   = payload.roomId;
      state.roomName = payload.roomName;
    },
    setInvalidIdCardCheckInScanned(state) {
      state.isInvalidCardIdScanned = true;
      state.appState = AppStateEnum.CardReaderReadCheckIn;
    },
    setInvalidIdCardCheckOutScanned(state) {
      state.isInvalidCardIdScanned = true;
      state.appState = AppStateEnum.CardReaderReadCheckOut;
    },
    setValidIdCardScanned(state) {
      state.isInvalidCardIdScanned = false;
    },
    setRoomNotConfigured(state) {
      state.appState = AppStateEnum.RoomNotConfigured;
    },
    setNoLicense (state) {
      state.appState = AppStateEnum.NoLicense;
    },
    setCardId (state, {payload}: PayloadAction<string|undefined>) {
      state.cardId = payload;
    },
    switchToCheckInOutComplete(state) {
      state.appState = AppStateEnum.CheckInOutComplete;
    },
    setApiError (state, { payload }: PayloadAction<ModalContent["message"]>) {
      state.apiErrorModal.isShow = true;
      state.apiErrorModal.message = payload;
    },
    showApiErrorModal (state) {
      state.apiErrorModal.isShow = true;
    },
    hideApiErrorModal (state) {
      state.apiErrorModal.message = undefined;
      state.apiErrorModal.isShow = false;
    },
    showInactivePopup(state, { payload }: PayloadAction<boolean>) {
      state.showInactivePopup = payload;
    },
  },
});

export const AppActions = AppSlice.actions;
export const AppReducer = AppSlice.reducer;

// SELECTORS

const selectSelf = (state: RootState) => state.app;
export const appSelector = createSelector(selectSelf, (state) => state);
export const appIsLoadingSelector = createSelector(selectSelf, (state) => state.isLoading);
export const appStateSelector = createSelector(selectSelf, (state) => state.appState);
export const isInvalidCardIdScannedSelector = createSelector(selectSelf, (state) => state.isInvalidCardIdScanned);
export const roomInfoSelector = createSelector(selectSelf, (state) => ({ roomId: state.roomId, roomName: state.roomName } as RoomInfo));
export const apiErrorSelector = createSelector(selectSelf, (state) => state.apiErrorModal);
