import { CommunicationObserver } from "../../App";
import { AppStateEnum } from "../../constants/enums/app-state.enum";
import { EventState } from '../../constants/enums/state.enum';
import { SignalRConstants } from '../../constants/signalr.constants';
import { handlerlog } from '../../utils/log.utils';
import { and, hasSession, mustBeExpected, entranceDoorOpenEvent, requireRoomId } from '../validators';
import { ExpectNextStates, StateHandler } from './common.handler';

export const EntranceDoorOpenedForCheckinHandler: StateHandler<typeof SignalRConstants.Events.DoorChangedEvent> = {
  state: EventState.EntranceDoorOpenedForCheckin,
  event: SignalRConstants.Events.DoorChangedEvent,
  validator: and(requireRoomId, hasSession, mustBeExpected, entranceDoorOpenEvent(true)),
  async handler(ctx, params) {
    ctx.doorSession.updateDoorState(params);
    
    handlerlog(this.state,
      `the entrance door is opened. Swith appState to: ${AppStateEnum.CheckIn}, waiting for both doors to be closed.`
    );

    await CommunicationObserver.entranceTurnOnMagnetLock();

    return ExpectNextStates.EntranceDoorClosedForCheckInOrEmergencyOnWarehouseDoor;
  },
};
