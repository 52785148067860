import { useState } from "react"
import { ListConstants } from "../../constants/list.constants"
import { css } from "../../utils/css.utils"
import { AutoScrollDiv } from "../AutoScrollDiv/AutoScrollDiv"
import { NextButton, PrevButton } from "../Button/IconButton"
import { Spinner } from "../Spinner/Spinner"
import { ListItem, ListItemEmpty } from "./ListItem"
import "./index.scss"

export interface ListProps<T> {
  items: T[],
  onRenderItem: (item: T) => JSX.Element,
  onItemClick?: (item: T) => void,
  onRenderEmpty?: () => JSX.Element,
  onRenderLoading?: () => JSX.Element,
  onUserInteraction?: VoidFunction,
  noDefaultPadding?: boolean,
  alwaysDisplayPaging?: boolean,
  isLoading?: boolean,
  autoScroll?: boolean,

  /**
   * Please just use only 4 or 5 for now :'(
   * This will adjust item's height.
   * 
   * TODO(knta): maybe make this more flexible?! Using CSS variables?!
   */
  maximumItems?: number,

  usePaging?: boolean,
}

export const List = <T,>(props: ListProps<T>) => {
  return (
    <div className={css("list", props.isLoading && "is-loading")}>
      {props.isLoading
        ? (
          <div className="list-container">
            {props.onRenderLoading
              ? props.onRenderLoading()
              : <Spinner />
            }
          </div>
        )
        : props.usePaging
        ? (
          <ListInnerPaging {...props} />
        )
        : (
          <ListInnerScroll {...props} />
        )
      }
    </div>
  )
}

const ListInnerScroll = <T,>(props: ListProps<T>) => {
  const isItemsEmpty = props.items.length === 0
  const perPage = props.maximumItems ?? ListConstants.checkInOutMaxItemsPerPage

  return (
    <AutoScrollDiv
      className={css(
        "list-container",
        "scrolling",
        perPage === ListConstants.settingsMaxItemsPerPage && "five-items",
      )}
      onUserInteraction={props.onUserInteraction}
      disableAutoScroll={!props.autoScroll}
    >
      {props.items.map((item, idx) =>
        <ListItem
          key={idx}
          item={item}
          onRender={props.onRenderItem}
          onClick={props.onItemClick}
          noDefaultPadding={props.noDefaultPadding}
        />
      )}
      {isItemsEmpty &&
        <div className="empty">
          {props.onRenderEmpty?.()}
        </div>
      }
    </AutoScrollDiv>
  )
}

const ListInnerPaging = <T,>(props: ListProps<T>) => {
  // starts with 1.
  const minimumPage = 1
  const [currentPage, setCurrentPage] = useState(minimumPage)

  const perPage = props.maximumItems ?? ListConstants.checkInOutMaxItemsPerPage

  const total = props.items.length
  const totalPages = Math.ceil(total / perPage)
  const lastIdx = total - 1
  const lowerBound = Math.min((currentPage - 1) * perPage, lastIdx)
  const upperBound = Math.min(currentPage * perPage - 1, lastIdx)

  const items = props.items.slice(lowerBound, upperBound + 1)

  const numberOfEmptyItems = items.length > 0 ? perPage - items.length : 0

  const onNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages))
  }

  const onPrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, minimumPage))
  }

  return (
    <>
      <AutoScrollDiv
        className={css(
          "list-container",
          perPage === ListConstants.settingsMaxItemsPerPage && "five-items"
        )}
        onUserInteraction={props.onUserInteraction}
        disableAutoScroll={!props.autoScroll}
      >
        {items.map((item, idx) =>
          <ListItem
            key={idx}
            item={item}
            onRender={props.onRenderItem}
            onClick={props.onItemClick}
            noDefaultPadding={props.noDefaultPadding}
          />
        )}
        {new Array(numberOfEmptyItems).fill(0).map((_, idx) =>
          <ListItemEmpty
            key={idx}
            children={<></>}
            noDefaultPadding={props.noDefaultPadding}
          />
        )}
        {total === 0 &&
          <div className="empty">
            {props.onRenderEmpty?.()}
          </div>
        }
      </AutoScrollDiv>
      {(props.alwaysDisplayPaging || props.items.length > 1) &&
        <div className="list-paging">
          <PrevButton className="btn prev-btn" onClick={onPrevPage} />
          <div className="paging-content">
            {currentPage}/{totalPages}
          </div>
          <NextButton className="btn next-btn" onClick={onNextPage} />
        </div>
      }
    </>
  )
}
