import { HttpClient } from '@onix/common';
import { StatusCodes } from 'http-status-codes';
import { CommunicationObserver, StateHandlerObserver } from '../App';
import { RootApiUrl } from '../constants/api.constants';
import { ApiResponseErrorCodeEnum } from '../constants/enums/api-response-error-code.enum';
import { TimerConstants } from '../constants/timer.constants';
import { ApiResponseError } from '../models/api-response.model';
import { ExpectNextStates } from '../signalr/handlers/common.handler';
import { AppActions } from '../states/app/app.slice';
import { CheckInOutActions } from '../states/app/check-in-out-state.slice';
import { appDispatch, store } from '../states/store';
import { errorlog, log } from '../utils/log.utils';

const dispatch = appDispatch;
const appActions = AppActions;

export const getAsync = <T>(
  url: string,
  request: object | undefined = undefined,
  isLoading: boolean = true,
  headers: any = getAuthorizationHeaders()
): Promise<T> => {
  detectShowLoading(isLoading);
  return HttpClient.getAsync(url, request, headers)
    .then(handleResponse)
    .catch(async (err: any) => {
      return handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

export const postAsync = <T>(
  url: string,
  request: object | undefined = undefined,
  isLoading: boolean = true,
  headers: any = getAuthorizationHeaders()
): Promise<T> => {
  detectShowLoading(isLoading);
  return HttpClient.postAsync(url, request, headers)
    .then(handleResponse)
    .catch(async (err: any) => {
      return handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

export const putAsync = <T>(
  url: string,
  request: object | undefined = undefined,
  isLoading: boolean = true,
  headers: any = getAuthorizationHeaders()
): Promise<T> => {
  detectShowLoading(isLoading);
  return HttpClient.putAsync(url, request, headers)
    .then(handleResponse)
    .catch(async (err: any) => {
      return handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

export const deleteAsync = (url: string, isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {
  detectShowLoading(isLoading);
  return HttpClient.deleteAsync(url, headers)
    .then(handleResponse)
    .catch(async (err: any) => {
      return await handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

function getAuthorizationHeaders() {
  const token = getToken();

  if (!token) {
    return {};
  }

  return {
    Authorization: `${token}`,
  };
}

const detectShowLoading = (isLoading: boolean) => {
  if (isLoading) {
    dispatch(appActions.showLoading());
  }
};

const detectHiddenLoading = (isLoading: boolean) => {
  if (isLoading) {
    dispatch(appActions.hideLoading());
  }
};

const getToken = () => {
  return store.getState().checkInOut.authenInfo?.token;
};

const handleError = async (response: any) => {
  if (response?.status === StatusCodes.UNAUTHORIZED) {
    // clearToken();
    // await login();
    return;
  }

  if (response?.data) {
    updateApiOnlineState();
    const error = response.data as ApiResponseError;
    await handleErrorValidateInfo(error);

    return Promise.reject(response.data);
  }

  await pingApiServer();

  return Promise.reject(response);
};

const handleResponse = async (response: any) => {
  updateApiOnlineState();
  return response;
};

const updateApiOnlineState = () => {
  if (!store.getState().app.isApiOnline) {
    appDispatch(AppActions.setApiOnlineState(true));
  }
};

const pingApiServer = async () => {
  // This variable exists to make sure we don't spam Redux.
  const isLastAPIServerOnlineStateOnline = () => store.getState().app.isApiOnline;

  const pingAPIServerIntervalId = setInterval(() => {
    HttpClient.getAsync(RootApiUrl)
      .then(() => {
        if (!isLastAPIServerOnlineStateOnline()) {
          log(`pingApiServer: API is online!`);
          appDispatch(AppActions.setApiOnlineState(true));
        }
        clearInterval(pingAPIServerIntervalId);
      })
      .catch((err) => {
        if (isLastAPIServerOnlineStateOnline()) {
          log(`pingApiServer: API is offline!`);
          appDispatch(AppActions.setApiOnlineState(false));
        }
      })
      .finally(() => {});
  }, TimerConstants.PingAPIServerIntervalInMillis);
};

const handleErrorValidateInfo = async (err: ApiResponseError) => {
  const { errorCode } = err;
  const cardId = store.getState().app.cardId;
  const ctx = StateHandlerObserver.context;

  ctx.updateExpectNextStates(ExpectNextStates.AnyStates);
  switch (errorCode) {
    case ApiResponseErrorCodeEnum.InvalidCardId:
      break;

    case ApiResponseErrorCodeEnum.TheRoomNotSetupAndInvalidPrivilege:
      await ctx.session.cancel();
      ctx.setRoomConfigured(false);
      appDispatch(AppActions.setRoomNotConfigured());
      CommunicationObserver.openEntranceDoorInside();
      break;

    case ApiResponseErrorCodeEnum.TheRoomNotSetupAndValidPrivilege:
      await ctx.session.cancel();
      ctx.setRoomConfigured(false);
      appDispatch(AppActions.setCardId(cardId));
      appDispatch(
        CheckInOutActions.setOnConfirmResult(() => {
          ctx.setRoomConfigured(true);
          ctx.session.end();
          ctx.updateExpectNextStates(ExpectNextStates.AnyStates);
          CommunicationObserver.openEntranceDoorInside();
          appDispatch(AppActions.switchToCheckInOutComplete());
        })
      );
      appDispatch(AppActions.startSetup());
      break;

    case ApiResponseErrorCodeEnum.InvalidLicense:
      await ctx.session.cancel();
      ctx.setRoomConfigured(true);
      appDispatch(AppActions.setNoLicense());
      break;

    case ApiResponseErrorCodeEnum.NoMoreLicense:
      await ctx.session.cancel();
      ctx.setRoomConfigured(false);
      appDispatch(AppActions.setNoLicense());
      break;

    case ApiResponseErrorCodeEnum.UnknownCode:
    default:
      errorlog(`unknown error code:`, err.originError);
      appDispatch(AppActions.setApiError(err.detail));
      break;
  }
};
