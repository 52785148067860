
import { css } from "../../utils/css.utils"
import "./index.scss"


export interface ListItemProps<T> {
  item: T,
  className?: string,
  onRender: (item: T) => JSX.Element,

  /**
   * Event when click on this list item. Also, has `clickable` class name when onClick is not undefined.
   */
  onClick?: (item: T) => void,
  noDefaultPadding?: boolean,
}

export const ListItem = <T,>(props: ListItemProps<T>) => {
  return (
    <div
      className={css(
        "list-item",
        props.className,
        props.noDefaultPadding && "no-default-padding",
        props.onClick && "clickable"
      )}
      onClick={() => props.onClick?.(props.item)}
    >
      {props.onRender(props.item)}
    </div>
  )
}

export interface ListItemEmptyProps {
  children: JSX.Element,
  noDefaultPadding?: boolean,
}

export const ListItemEmpty = (props: ListItemEmptyProps) => (
  <div
    className={css(
      "list-item list-item-empty",
      props.noDefaultPadding && "no-default-padding"
    )}
  >
    {props.children}
  </div>
)