
/**
 * Run mode for state handler.
 */
export enum StateHandlerRunModeEnum {
  /**
   * Default mode, run when:
   * 1. Current state handler's validator validated successfully.
   * 2. Current state handler is expected by previous state handler for current expect next states is `Any`.
   * 3. Only one state handler is run.
   */
  Default,

  /**
   * Standalone mode, run when:
   * 1. No handler in default mode matched. 
   * 2. Current state handler's validator validated successfully.
   * 3. Only one state handler is run.
   */
  Standalone,
}