import { environment as dev } from "../environments/environment";
import { environment as beta } from "../environments/environment.beta";
import { environment as test } from "../environments/environment.test";
import { environment as prod } from "../environments/environment.prod";
import { EnvironmentSettings } from "../models/app-settings.model";

const configs = {
  dev: dev,
  beta: beta,
  test: test,
  prod: prod,
} as any;

/**
 * This is the static settings for the app.
 */
const ApplicationSetting: EnvironmentSettings = configs[process.env.REACT_APP_STATE || "dev"];

export default ApplicationSetting;

export const ApplicationVersion = process.env.REACT_APP_VERSION || "1.0.0.0";

const serverNameMap = {
  [dev .environment]: "Development",
  [beta.environment]: "Beta",
  [test.environment]: "Test",
  [prod.environment]: "Production",
}

export const ServerName = serverNameMap[ApplicationSetting.environment]
