import { useEffect, useRef, useState } from "react"
import { SetIntervalType, emptyVoidFunction } from "../utils/functional.utils"
import { TimerConstants } from "../constants/timer.constants"

type UseTimerControl = {
  start: (seconds: number, onFinished: VoidFunction) => void,
  cancel: VoidFunction,
}

export type UseTimerReturn = [number, UseTimerControl]

export const useTimer = (): UseTimerReturn => {
  const [remainingSeconds, setRemainingSeconds] = useState(0)
  const [intervalId, setIntervalId] = useState<undefined | SetIntervalType>(undefined)

  const onFinishedCb = useRef<VoidFunction>(emptyVoidFunction)

  const start: UseTimerControl["start"] = (seconds, onFinished) => {
    setRemainingSeconds(seconds)
    onFinishedCb.current = onFinished
    
    const intervalId = setInterval(() => {
      setRemainingSeconds(prev => prev - 1)
    }, TimerConstants.BaseTimerInMillis)

    setIntervalId(intervalId)
  }

  const cancel: UseTimerControl["cancel"] = () => {
    setIntervalId(prev => {
      clearInterval(prev)
      return undefined
    })
    setRemainingSeconds(0)
  }

  useEffect(() => {
    if (intervalId && remainingSeconds === 0) {
      // Timer runs out.
      clearInterval(intervalId)
      setIntervalId(undefined)

      onFinishedCb.current()
    }
  }, [remainingSeconds, intervalId])

  return [remainingSeconds, {
    start,
    cancel,
  }]
}
