import { ApiConstants } from "../constants/api.constants"
import { AuthenticationInfo } from "../models/authentication-info.model"
import { DeviceSetup } from "../models/device-setup.model"
import { EquipmentCheckInOutItemModel } from "../models/equipment-check-inout.model"
import { EquipmentInfo } from "../models/equipment-info.model"
import { ExternalUserPrivilege } from "../models/external-user-privilege.model"
import { Item } from "../models/setup.model"
import { ToolStoreRoom } from "../models/tool-store-room.model"
import { getAsync, postAsync, putAsync } from "./base.service"

const getAuthToken = async (roomId: string, cardId: string): Promise<AuthenticationInfo> => {
  return postAsync(ApiConstants.users.getToken(), { roomId, cardId })
}

const getEquipmentByGlobalId = (globalId: string): Promise<EquipmentInfo> => {
  return getAsync<EquipmentInfo>(ApiConstants.equipment.getEquipmentByGlobalId(globalId))
}

const getRoomSetting = async (roomId: string): Promise<DeviceSetup> => {
  return postAsync(ApiConstants.deviceSetups.getRoomSetting(), {roomId})
}

const updateCheckInOutEquipment = (items: EquipmentCheckInOutItemModel[], forceUpdate: boolean = false): Promise<boolean> => {
  return postAsync(ApiConstants.checking.checkInOutEquipment(), { items, forceUpdate })
}

const getCompaniesWithPrivilege = async (cardId: string|undefined): Promise<ExternalUserPrivilege[]> => {
  return postAsync(ApiConstants.settings.getcompaniesWithPrivilege(), { cardId })
}
const getCompanyPrivileges = async (cardId: string|undefined): Promise<Item[]> => {
  return postAsync(ApiConstants.settings.getCompanyPrivileges(), { cardId })
}
const getLocations = (companyId: number): Promise<Item[]> => {
  return getAsync(ApiConstants.settings.getLocations(companyId))
}
const getAreas = (companyId: number, locationId: number): Promise<Item[]> => {
  return getAsync(ApiConstants.settings.getAreas(companyId, locationId))
}
const createRoomSetting = (toolStoreRoom: ToolStoreRoom): Promise<string> =>{
  return postAsync(ApiConstants.deviceSetups.registerToolStoreRoom(),
  {
    userCardId:toolStoreRoom.userCardId,
    roomId: toolStoreRoom.roomId,
    roomName: toolStoreRoom.roomName,
    companyId: toolStoreRoom.company.id,
    locationId:toolStoreRoom.location.id,
    areaId:toolStoreRoom.area.id,
    languageId:toolStoreRoom.language.id,
    confirmTimeoutInSecond:toolStoreRoom.confirmTimeoutInSecond,
    idleTimeoutInSecond: toolStoreRoom.idleTimeoutInSecond,
  })
}
const checkLatestVersion = (version: string): Promise<boolean> => {
  return getAsync(ApiConstants.settings.checkLatestVersion(version))
}

const updateLanguage = (roomId: string, languageId: number): Promise<boolean> => {
  return putAsync(ApiConstants.settings.updateLanguage(), { roomId, languageId })
}

export const ApiService = {
  getAuthToken,
  getEquipmentByGlobalId,
  updateCheckInOutEquipment,
  getRoomSetting,
  getCompanyPrivileges,
  getLocations,
  getAreas,
  createRoomSetting,
  getCompaniesWithPrivilege,
  checkLatestVersion,
  updateLanguage,
}
