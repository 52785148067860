import { CommunicationObserver } from "../../App"
import { SignalRConstants } from '../../constants/signalr.constants'
import { ApiService } from '../../services/api.service'
import { Result } from "../../utils/error-handling.utils"
import { isApiServerDisconnected } from "../../utils/functional.utils"
import { handlerlog } from '../../utils/log.utils'
import { ExpectNextStates, StandaloneHandler } from './common.handler'

/**
 * The default handler when there is no valid/matched handler to handle current event.
 * This function should have handled all cases where it should be ignored or update states like doors.
 *
 * **NOTE**: If there are at least one matched handler, this function won't be run. So ensure the states are set correctly when handle those events.
 */
export const DefaultStandaloneHandler: StandaloneHandler<"DoorChangedEvent" | "Status" | "DoorButtonPressed"> = {
  events: [
    SignalRConstants.Events.DoorChangedEvent,
    SignalRConstants.Events.Status,
    SignalRConstants.Events.DoorButtonPressed,
  ],
  async handler(ctx, params) {
    const currentHandlerName = "DefaultStandaloneHandler"

    const standaloneHandlerLog = (...args: any[]) => handlerlog(currentHandlerName as any, ...args);

    standaloneHandlerLog(`run a unhandled event, event="${params.event}", params:`, params);
  
    switch (params.event) {
      case SignalRConstants.Events.DoorChangedEvent:
        ctx.doorSession.updateDoorState(params);
        if(!ctx.session.hasSession && !ctx.doorSession.isEntranceDoorOpened && !ctx.doorSession.isWarehouseDoorOpened){
          ctx.session.checkLatestClientVersion();
        }
        break;
  
      case SignalRConstants.Events.Status:
        ctx.updateRoomInfoFromStatus(params.status);
        const apiResult = await Result.fromAPIPromise(ApiService.getRoomSetting(params.status.sluiceId));

        if (apiResult.isOk) {
          const roomSetting = apiResult.data;
          ctx.updateRoomSettings(roomSetting);
          standaloneHandlerLog(`roomSettings:`, roomSetting);
          break;
        }

        standaloneHandlerLog(`get status failed`);

        break;

      case SignalRConstants.Events.DoorButtonPressed:
        if (isApiServerDisconnected()) {
          standaloneHandlerLog(`open "${params.door}" door because API server is disconnected!`)
          CommunicationObserver.openDoorInside(params.door);
          break
        }

        standaloneHandlerLog(`NOTICE: This event currently do nothing in standalone handler.`)
        break
    }
  
    return ExpectNextStates.Unchanged;
  },
}
