
export enum DoorEnum {
  Entrance = "Entrance",
  Warehouse = "Warehouse",
}

export enum DoorLocationEnum {
  Outside = "Outside",
  Inside = "Inside",
}

export enum SignalRLicenseStatusEnum {
  Missing = "Missing",
  HardwareMismatch = "HardwareMismatch",
  NotValid = "NotValid",
  Valid = "Valid",
}

export enum SignalRDeviceStatusEnum {
  MissingConfig = "MissingConfig",
  LicenseValidationFail = "LicenseValidationFail",
  NotRunning = "NotRunning",
  Running = "Running",
}
