/**
 * Localstorage key.
 */
export const SettingKeyConstants = {
  SignalRUrl: "SignalRUrl",
  ConfirmTimeoutInSecond: "ConfirmTimeoutInSecond",
  IdleTimeoutInSecond: "IdleTimeoutInSecond",
  LanguageId: "LanguageId",
  Language: "Language",
  RoomInfo: "RoomInfo",
  DoorState: "DoorState",
  LogServerSideEnabled: "LogServerSideEnabled",
  LogClientSideEnabled: "LogClientSideEnabled", 
  LogClientSideRecords: "__log_records",
  ShowDebugLog: "__show_debug_log",
}
