import { StateHandlerRunModeEnum } from "../../constants/enums/handler-type.enum";
import { EventState } from "../../constants/enums/state.enum";
import { SignalRConstants } from "../../constants/signalr.constants";
import { handlerlog } from "../../utils/log.utils";
import { and, hasSession, isCheckOutFlow, roomConfigured, scanSessionIsOn, warehouseDoorButtonEvent } from "../validators";
import { ExpectNextStates, StateHandler } from "./common.handler";

export const WarehouseDoorButtonPressedInCheckOutSessionHandler: StateHandler<typeof SignalRConstants.Events.DoorButtonPressed> = {
  state: EventState.WarehouseDoorButtonPressedInCheckOutSession,
  event: SignalRConstants.Events.DoorButtonPressed,
  mode: StateHandlerRunModeEnum.Standalone,
  validator: and(
    roomConfigured,
    hasSession,
    scanSessionIsOn,            // Both doors are closed!
    warehouseDoorButtonEvent,   // Only accept the warehouse button.
    isCheckOutFlow,             // Check out flow only!
  ),
  async handler(ctx) {
    handlerlog(this.state, `cancel current session (open warehouse door on check out session).`);
    
    await ctx.session.cancel();

    return ExpectNextStates.AnyStates;
  },
};
