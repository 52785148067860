import { CommunicationObserver } from "../../App";
import { EventState } from "../../constants/enums/state.enum";
import { SignalRConstants } from "../../constants/signalr.constants";
import { CheckInOutActions } from "../../states/app/check-in-out-state.slice";
import { appDispatch } from "../../states/store";
import { and, hasSession, mustBeExpected, requireRoomId, warehouseDoorOpenEvent } from "../validators";
import { ExpectNextStates, StateHandler } from "./common.handler";

export const WarehouseDoorClosedForCheckinHandler: StateHandler<typeof SignalRConstants.Events.DoorChangedEvent> = {
  state: EventState.WarehouseDoorClosedForCheckin,
  event: SignalRConstants.Events.DoorChangedEvent,
  validator: and(requireRoomId, hasSession, mustBeExpected, warehouseDoorOpenEvent(false)),
  async handler(ctx, params) {
    // Warehouse door state is closed.
    ctx.doorSession.updateDoorState(params);

    // TODO(knta): This is unlikely to happen! Come up with something understandable.
    if (ctx.doorSession.isEntranceDoorOpened) {
      // Entrance door is opened, expect it to close.
      return ExpectNextStates.EntranceDoorClosedForCheckin;
    }

    await CommunicationObserver.rfidReaderStart();

    appDispatch(CheckInOutActions.switchToWaitForConfirmingResults());

    ctx.session.onSessionEnd(() => {
      // NOTE: the ctx.session will automatically submit the results when timer is run out.
      ctx.updateExpectNextStates(ExpectNextStates.AnyStates);
    });

    await ctx.session.startScanAndRegisterEndSession();

    return ExpectNextStates.RfidReaderRead;
  },
};
