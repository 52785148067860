import { CommunicationObserver } from "../../App";
import { EventState } from '../../constants/enums/state.enum';
import { SignalRConstants } from '../../constants/signalr.constants';
import { handlerlog } from '../../utils/log.utils';
import { and, hasSession, isCheckInFlow, scanSessionIsOn, roomConfigured, not, warehouseDoorOpenEvent, mustBeExpected } from '../validators';
import { ExpectNextStates, StateHandler } from './common.handler';

export const EmergencyWarehouseDoorBeforeScanningCheckInSessionHandler: StateHandler<typeof SignalRConstants.Events.DoorChangedEvent> = {
  state: EventState.EmergencyWarehouseDoorBeforeScanningCheckInSession,
  event: SignalRConstants.Events.DoorChangedEvent,
  validator: and(
    roomConfigured,
    hasSession,
    mustBeExpected,
    isCheckInFlow,                  // In check in flow,
    not(scanSessionIsOn),           // and the scanning session is NOT going on,
    warehouseDoorOpenEvent(true),   // the warehouse door is suddenly opened, then it's emergency.
  ),
  async handler(ctx, params) {
    ctx.doorSession.updateDoorState(params);
    
    handlerlog(this.state,
      `[EMERGENCY/CheckIn] cancel current session since it's not even scanning.`
    );

    await ctx.session.emergencyCancel();
    await CommunicationObserver.resetToStandBy();

    return ExpectNextStates.AnyStates;
  },
};
