
export const TimerConstants = {
  BaseTimerInMillis: 1000 - 3,          // 1 second but minus 3 milliseconds because javascript sucks :D
  SessionStartTimeout: 15,              // Seconds
  SecondToMillis: 1000,
  PingAPIServerIntervalInMillis: 2000,  // When offline, ping API server every 2 seconds.
  DefaultConfirmTimeoutInSecond: 30,    // 30s timeout for user to confirm check in/out.
  DefaultIdleTimeoutInSecond: 15,       // 15 seconds timeout when request open door is fired.
  NoTimeoutValue: 0,                    // Use 0 as no timeout value.
  InactivityTimeoutInSecond: 60,        // 1 minute.
  ConfirmInactivityTimeoutInSecond: 60, // After InactivityTimeoutInSecond was run out, another 30s for user to confirm their activity.
}
