import { CancelIcon, CheckIcon } from "../components/Icon/Icon"
import { AppStateEnum } from "./enums/app-state.enum"

type CommonMessageByAppState =
  | AppStateEnum.CheckInOutComplete
  | AppStateEnum.RoomNotConfigured
  | AppStateEnum.NoLicense
  | AppStateEnum.SignalRReconnected

type CommonPageContent = {
  headerKey: string | undefined,
  messageKey: string,
  icon: JSX.Element | undefined,
}

const create = (messageKey: string, headerKey?: string | undefined, icon?: JSX.Element): CommonPageContent => ({
  headerKey,
  messageKey,
  icon,
})

const contentByAppState: Map<CommonMessageByAppState, CommonPageContent> = new Map([
  [AppStateEnum.CheckInOutComplete, create("AppInspect.ToolGateScanComplete"   , undefined, <CheckIcon />)],
  [AppStateEnum.RoomNotConfigured,  create("AppInspect.ToolGateAppNoConfig"    , undefined, <CancelIcon />)],
  [AppStateEnum.NoLicense,          create("AppInspect.ToolGateLicenseNoConfig", undefined, <CancelIcon />)],
  [AppStateEnum.SignalRReconnected, create("AppInspect.ToolGateReconnected"    , undefined, <CheckIcon />)]
])

export const CommonPageConstants = {
  contentByAppState,
}
