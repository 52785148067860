import { css } from "../../utils/css.utils"
import "./index.scss"

export interface InfoScreenProps {
  className?: string,
  header?: string | JSX.Element,
  icon?: JSX.Element,
  message: string | JSX.Element,
}

/**
 * Default page for most information screen (display a message with an icon).
 */
export const InfoScreen = (props: InfoScreenProps) => {
  return (
    <div className={css("page-content info-screen", props.className)}>
      {props.header &&
        <div className="page-header uppercase">{props.header}</div>
      }
      <div className="message center-screen">
        {props.icon &&
          <div className="info-screen-icon">{props.icon}</div>
        }
        <div className="info-screen-message">{props.message}</div>
      </div>
    </div>
  )
}
