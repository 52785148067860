import { CommunicationObserver } from "../../App";
import { EventState } from "../../constants/enums/state.enum";
import { SignalRConstants } from "../../constants/signalr.constants";
import { CheckInOutActions } from "../../states/app/check-in-out-state.slice";
import { appDispatch } from "../../states/store";
import { and, hasSession, mustBeExpected, requireRoomId, warehouseDoorOpenEvent } from "../validators";
import { ExpectNextStates, StateHandler } from "./common.handler";

export const WarehouseDoorClosedForCheckoutHandler: StateHandler<typeof SignalRConstants.Events.DoorChangedEvent> = {
    state: EventState.WarehouseDoorClosedForCheckout,
    event: SignalRConstants.Events.DoorChangedEvent,
    validator: and(requireRoomId, hasSession, mustBeExpected, warehouseDoorOpenEvent(false)),
    async handler(ctx, params) {
        ctx.doorSession.updateDoorState(params);

        if(ctx.doorSession.isEntranceDoorOpened) {
            return ExpectNextStates.EntranceDoorClosedForCheckOutOrEmergencyOnWarehouseDoor;
        }

        await CommunicationObserver.rfidReaderStart();

        ctx.session.onSessionEnd(() => {
            ctx.updateExpectNextStates(ExpectNextStates.AnyStates)
        });

        await ctx.session.startScanAndRegisterEndSession();

        appDispatch(CheckInOutActions.switchToWaitForConfirmingResults());

        return ExpectNextStates.RfidReaderRead;
    }
}