import { store } from "../states/store"

export type SetTimeoutType = ReturnType<typeof setTimeout>
export type SetIntervalType = ReturnType<typeof setInterval>
export type MaybeAsyncFn<T> = () => Promise<T> | T
export type MaybeAsync<T> = T extends Promise<infer U> ? Promise<U> : T
export type MaybeFn<T> = T extends Function ? () => T : T
export type ValueOf<T> = T[keyof T]
export type KeyOfType<T> = {
  [key in keyof T]: key
}

export const runMaybeAsyncFn = <T>(maybeFn: MaybeAsyncFn<T>): Promise<T> => {
  if (maybeFn instanceof Promise) {
    return maybeFn
  }

  const result = maybeFn()
  return result instanceof Promise ? result : Promise.resolve(result)
}

export function range(size: number): Generator<number>
export function range(start: number, stop?: number): Generator<number>
export function range(start: number, reversed: boolean): Generator<number>

export function* range(start: number, stopOrReversed: number | undefined | boolean = undefined): Generator<number> {
  if (stopOrReversed === true) {
    // It's reversed.
    let stop = start - 1
    for (let i = stop; i >= 0; --i) {
      yield i
    }
    return
  }

  let realStart = !stopOrReversed ? 0 : start
  let realStop = !stopOrReversed ? start : stopOrReversed

  if (realStop < realStart) {
    return
  }

  for (let i = realStart; i < realStop; ++i) {
    yield i
  }
}

export const emptyVoidFunction = () => {}

export const isNotNullOrUndefined = <T>(obj: T | null | undefined): obj is T => !!obj

export const isApiServerDisconnected = () => !store.getState().app.isApiOnline

export const next = <T>(list: T[], item: T): T | undefined => {
  const itemIdx = list.findIndex(i => i === item)

  if (itemIdx < 0) {
    return
  }

  return list[itemIdx + 1]
}

/**
 * Ultility functions for iterator.
 */
export const IterUtils = {
  /** Check if any item in iterator found with pred */
  any: <T>(iter: IterableIterator<T>, pred: (item: T) => boolean) => {
    for (const item of iter) {
      if (pred(item)) {
        return true
      }
    }

    return false
  },
}
