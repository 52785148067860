import i18next from "i18next"
import { f as f$ } from "../utils/language.utils"
import { Item, SetupData } from "../models/setup.model"
import { SettingStepEnum } from "./enums/setting-step.enum"
import { LanguageName } from "./languages.constants"
import { ValueConstants } from "./value-constant"

const t = i18next.t
const f = f$(t)

const getConfirmTimeoutOptions = (): Item[] => [
  { id: 0, description: t('CommonResource.NoTimeout') },
  { id: 15, description: f('AppInspect.Seconds', { seconds: 15 }) },
  { id: 30, description: f('AppInspect.Seconds', { seconds: 30 }), recommended: true},
  { id: 60, description: f('AppInspect.Seconds', { seconds: 60 }) },
  { id: 300, description: f('AppInspect.Seconds', { seconds: 300 }) },
]

const getIdleTimeoutOptions = (): Item[] => [
  { id: 15, description: f('AppInspect.Seconds', { seconds: 15 }), recommended: true},
  { id: 30, description: f('AppInspect.Seconds', { seconds: 30 }) },
  { id: 60, description: f('AppInspect.Seconds', { seconds: 60 }) },
  { id: 120, description: f('AppInspect.Seconds', { seconds: 120 }) },
]

const MessageBySettingStep = new Map([
  [SettingStepEnum.Language, "AppInspect.ToolGateSettingLanguage"],
  [SettingStepEnum.Company, "AppInspect.ToolGateSettingCompany"],
  [SettingStepEnum.Location, "AppInspect.ToolGateSettingLocation"],
  [SettingStepEnum.Area, "AppInspect.ToolGateSettingArea"],
  [SettingStepEnum.ConfirmTimeoutInSecond, "AppInspect.ToolGateSettingTimeout"],
  [SettingStepEnum.IdleTimeoutInSecond, "AppInspect.ToolGateSettingIdleTimeout"],
  [SettingStepEnum.Summarize, "AppInspect.ToolGateSettingConfirm"],
])

export const SetupConstants = {
  get ConfirmTimeoutOptions() { return getConfirmTimeoutOptions() },
  get IdleTimeoutOptions() { return getIdleTimeoutOptions() },
  LanguageOptions: LanguageName satisfies Item[] as Item[],
  StepsWithCompany: [
    SettingStepEnum.Language,
    SettingStepEnum.Company,
    SettingStepEnum.Location,
    SettingStepEnum.Area,
    SettingStepEnum.ConfirmTimeoutInSecond,
    SettingStepEnum.IdleTimeoutInSecond,
    SettingStepEnum.Summarize,
  ],
  StepsWithoutCompany: [
    SettingStepEnum.Language,
    SettingStepEnum.Location,
    SettingStepEnum.Area,
    SettingStepEnum.ConfirmTimeoutInSecond,
    SettingStepEnum.IdleTimeoutInSecond,
    SettingStepEnum.Summarize,
  ],
  MessageBySettingStep,
  DefaultRoomData: {
    language: { id: 0, description: "" },
    company: { id: 0, description: "" },
    location: { id: 0, description: "" },
    area: { id: 0, description: "" },
    confirmTimeoutInSecond: { id: 0, description: "" },
    idleTimeoutInSecond: { id: 0, description: "" },
  } satisfies SetupData as SetupData,
  NoAreaItem: {
    id: ValueConstants.NULL_INT,
    get description() { return `- ${t("AppInspect.NoArea")} -` }
  } satisfies Item as Item,
}
