
import { format, parseISO } from "date-fns"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { dateTimeFormat } from "../../constants/dateTime.constant"
import { AppStateEnum, CheckInOutStateEnum } from "../../constants/enums/app-state.enum"
import { useFormatTranslation } from "../../hooks/useFormatTranslation"
import { AppActions, appSelector } from "../../states/app/app.slice"
import { CheckInOutActions, checkInOutInfoSelector } from "../../states/app/check-in-out-state.slice"
import { appDispatch } from "../../states/store"
import { getNextControlDueIcon, getTranslationDueStatus, isEquipmentInvalid } from "../../utils/equipment.utils"
import { Button, CancelButton, ConfirmButton } from "../Button/Button"
import { InfoIcon } from "../Icon/Icon"
import { InfoScreen } from "../InfoScreen/InfoScreen"
import { InfoScreenColumns } from "../InfoScreenColumns/InfoScreenColumns"
import { List } from "../List/List"
import "./index.scss"
import { css } from "../../utils/css.utils"
import { cancelInactivityTimer, startInactivityTimer } from "../../utils/common.utils"
import { Modal } from "../Modal/Modal"
import { useTimer } from "../../hooks/useTimer"
import { TimerConstants } from "../../constants/timer.constants"
import { getGlobalStateContext } from "../../utils/signalr.utils"

const display = <T,>(str: NonNullable<T> | null | undefined) => str ?? "-"

export const CheckInOut = () => {
  const [t] = useTranslation()
  const f = useFormatTranslation(t)
  const checkInOutInfo = useSelector(checkInOutInfoSelector)
  const appState = useSelector(appSelector)
  const [isInactivityWarningShowed, setIsInactivityWarningShowed] = useState(false)

  const [inactivePopupRemainingSeconds, {
    start: startInactivePopupTimer,
    cancel: cancelInactivePopupTimer,
  }] = useTimer()

  const ctx = getGlobalStateContext()

  const noTimeoutMode = ctx.session.isScanning && ctx.session.hasNoTimeout

  const isCheckIn = appState.appState === AppStateEnum.CheckIn
  const isCheckOut = appState.appState === AppStateEnum.CheckOut
  const header = isCheckIn ? t("AppInspect.ToolGateCheckIn") : t("AppInspect.ToolGateCheckOut")
  const isCheckInOrCheckOut = isCheckIn || isCheckOut
  const isStateScanning = checkInOutInfo.checkInOutState === CheckInOutStateEnum.WaitForConfirmingResults
  const isStateUninit = checkInOutInfo.checkInOutState === CheckInOutStateEnum.Uninit

  const equipmentList = checkInOutInfo.equipmentInfo

  const invalidEquipmentList = equipmentList.filter(eq => isEquipmentInvalid(eq, isCheckOut))
  const invalidEquipmentCounter = invalidEquipmentList.length
  const equipmentCounter = equipmentList.length

  const showInactiveModal = appState.showInactivePopup && noTimeoutMode

  useEffect(() => {
    if (appState.showInactivePopup) {
      startInactivePopupTimer(TimerConstants.ConfirmInactivityTimeoutInSecond, () => {
        ctx.session.end()
      })
      return 
    }

    cancelInactivePopupTimer()
  
  // Use only one dependency because: I want this effect to only run once when appState.showInactivePopup changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.showInactivePopup])

  useEffect(() => {
    // First time switch in to AppStateEnum.CheckIn/CheckOut, should start switching to WaitForClosingDoors.
    if (isCheckInOrCheckOut) {
      appDispatch(CheckInOutActions.switchToWaitForClosingDoors())
    }
  }, [isCheckInOrCheckOut])

  if (!isCheckInOrCheckOut || isStateUninit) {
    return <></>
  }

  const isWaitingForClosingDoors = checkInOutInfo.checkInOutState === CheckInOutStateEnum.WaitForClosingDoors

  if (isWaitingForClosingDoors) {
    return (
      <InfoScreen
        className="check-in-page"
        header={header}
        message={t("AppInspect.ToolGateCloseDoors")}
        icon={<InfoIcon />}
      />
    )
  }

  const translateObject = equipmentCounter === 0
    ? undefined
    : {
      numOfEquipment: invalidEquipmentCounter || equipmentCounter,
      total: equipmentList.length,
    }

  const scanningMessage = equipmentCounter === 0
    ? t("AppInspect.ToolGateConfirmMessageNoEq")
    : invalidEquipmentCounter > 0
      ? f("AppInspect.ToolGateConfirmMessageInvalidEq", translateObject)
      : f("AppInspect.ToolGateConfirmMessage", translateObject)

  // const countdownText = f("AppInspect.ToolGateSecondsLeft", {
  //   seconds: remainingSeconds
  // })

  // const onNeedService = (equipment: EquipmentInfo) => {
  //   if (isCheckIn) {
  //     appDispatch(CheckInOutActions.toggleNeedService(equipment))
  //   }
  // }

  const displayItems = invalidEquipmentCounter > 0 ? invalidEquipmentList : equipmentList
  
  const onConfirm = () => {
    ctx.session.end()
    cancelInactivityTimer()
    cancelInactivePopupTimer()
  }

  const onCancel = () => {
    ctx.session.cancel()
    cancelInactivityTimer()
    cancelInactivePopupTimer()
  }

  const onConfirmActive = () => {
    cancelInactivePopupTimer()
    appDispatch(AppActions.showInactivePopup(false))
    setIsInactivityWarningShowed(true)
  }

  const onUserInteraction = () => {
    if (!noTimeoutMode || isInactivityWarningShowed) {
      return
    }

    startInactivityTimer()
  }

  return (
    <>
      <Modal
        isOpen={showInactiveModal}
        header={t("CommonResource.Inactivity")}
        content={t("AppInspect.StillUsingOnixCheckout")}
        footer={
          <Button
            label={<>
              <span className="uppercase">{t("CaptionResource.Yes")} </span>
              ({inactivePopupRemainingSeconds})
            </>}
            className="p-inline-30"
            onClick={onConfirmActive}
          />
        }
      />
      <InfoScreenColumns
        className="check-inout-page"
        leftColumn={
          <>
            <div className="header">{header}</div>
            <div
              className="scanning-message"
              dangerouslySetInnerHTML={{
                __html: scanningMessage,
              }}
            />
            {invalidEquipmentCounter === 0 &&
              <ConfirmButton
                className="confirm-btn"
                onClick={onConfirm}
                label={
                  <div className="confirm-text">
                    {t("AppInspect.ToolGateConfirm")}
                  </div>
                }
              />
            }
            <CancelButton
              className="cancel-btn"
              onClick={onCancel}
              label={t("AppInspect.Cancel")}
            />
          </>
        }
        rightColumn={
          <List
            items={displayItems}
            noDefaultPadding
            onUserInteraction={onUserInteraction}
            autoScroll
            onRenderEmpty={() => (
              <div className="no-records-found">
                {t("AppInspect.ToolGateNoRecordsFound")}
              </div>
            )}
            onRenderItem={(item) =>
              <div className="equipment-item">
                {item.isNotFound && (
                  <div className="eq-not-found">{t("AppInspect.ToolGateNotFoundEquipment")}</div>
                )}
                {!item.isNotFound && (
                  <div className="info">
                    <div className="info-left">
                      <div className="row serial-number">
                        {display(item.serialNumber)}
                        {" - "}
                        {display(item.ownerEquipmentID)}
                      </div>
                      <div className="row">
                        {display(item.producer)}
                      </div>
                      <div className="row">
                        {display(item.type)}
                      </div>
                      <div className="row">
                        {display(item.typeInfo)}
                      </div>
                      <div className="row">
                        {display(item.placement)}
                      </div>
                    </div>
                    <div className="info-right">
                      <div className="row">
                        {item.mandatoryDueStatus
                          ? (
                            <>
                              {t(getTranslationDueStatus(item.mandatoryDueStatus))}
                              <div 
                                className={css(
                                  getNextControlDueIcon(item.mandatoryDueStatus),
                                  "status-icon",
                                )}
                              ></div>
                            </>
                          )
                          : "-"
                        }
                      </div>
                      <div className="row">
                        {item.nextControlDue
                          ? format(parseISO(item.nextControlDue ?? ''), dateTimeFormat.stringDateFormat)
                          : "-"
                        }
                      </div>
                    </div>
                  </div>
                )}
                {/* Temporary disable need service button. */}
                {/* <div className="separator"></div>
                <div className="actions">
                  {!item.isNotFound && (
                    <>
                      {item.isNeedService &&
                        <div className="need-service-text">
                          {t("AppInspect.ToolGateNeedService")}
                        </div>
                      }
                      {isCheckIn &&
                        <div className="need-service-btn">
                          <SecondaryButton
                            label={t("AppInspect.ToolGateNeedService")}
                            onClick={() => onNeedService(item)}
                            disabled={isCheckOut || item.isNotFound}
                          />
                        </div>
                      }
                    </>
                  )}
                </div> */}
              </div>
            }
          />
        }
      />
    </>
  )
}
