import { Dispatch, SetStateAction, useState } from "react";

export type SetValueAction<T> = <K extends keyof T>(name: K, value: T[K]) => void;

/**
 * This is like the React's `useState` but the setter is set value by key-value pair.
 */
export function useCompositeState<S = undefined>(): [S | undefined, SetValueAction<S>, Dispatch<SetStateAction<S | undefined>>];
export function useCompositeState<S>(initialState: S | (() => S)): [S, SetValueAction<S>, Dispatch<SetStateAction<S>>];

export function useCompositeState<S>(initialState?: S) {
  const getFirstState = () => typeof initialState === "object" ? ({...initialState}) : initialState;
  const [state, stateSetter] = useState(getFirstState);

  const setValue: SetValueAction<S> = (name, value) => {
    stateSetter(prev => ({
      ...prev,
      [name]: value
    }) as S);
  };

  return [state, setValue, stateSetter]
}
