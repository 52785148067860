import { List } from '../List/List';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useState, useEffect, useMemo } from 'react';
import { SettingStepEnum } from '../../constants/enums/setting-step.enum';
import { Item, SetupData } from '../../models/setup.model';
import { AppActions, appSelector } from '../../states/app/app.slice';
import { useSelector } from 'react-redux';
import { ApiService } from '../../services/api.service';
import { ToolStoreRoom } from '../../models/tool-store-room.model';
import { i18n, parseLanguageIdToLanguageShort } from '../../constants/languages.constants';
import { ConfirmButton } from '../Button/Button';
import { appDispatch } from '../../states/store';
import { useAsyncState } from "../../hooks/useAsyncState";
import { ListConstants } from "../../constants/list.constants";
import { getGlobalStateContext } from '../../utils/signalr.utils';
import { SetupConstants } from '../../constants/setup.constants';
import { useCompositeState } from '../../hooks/useCompositeState';
import { KeyOfType, MaybeAsyncFn, next, runMaybeAsyncFn } from '../../utils/functional.utils';
import { StepItem, Stepper, StepperProps } from '../Stepper/Stepper';
import { errorlog } from '../../utils/log.utils';
import { AppState } from '../../states/app/app.slice';
import { css } from '../../utils/css.utils';

const FieldName = {
  area: "area",
  company: "company",
  confirmTimeoutInSecond: "confirmTimeoutInSecond",
  idleTimeoutInSecond: "idleTimeoutInSecond",
  language: "language",
  location: "location",
} satisfies KeyOfType<SetupData>

const FieldNameBySettingStep = new Map([
  [SettingStepEnum.Language, FieldName.language],
  [SettingStepEnum.Company, FieldName.company],
  [SettingStepEnum.Location, FieldName.location],
  [SettingStepEnum.Area, FieldName.area],
  [SettingStepEnum.ConfirmTimeoutInSecond, FieldName.confirmTimeoutInSecond],
  [SettingStepEnum.IdleTimeoutInSecond, FieldName.idleTimeoutInSecond],
])

const setupDataToToolStoreRoom = (setupData: SetupData, appState: AppState): ToolStoreRoom => ({
  ...setupData,
  confirmTimeoutInSecond: setupData.confirmTimeoutInSecond.id,
  idleTimeoutInSecond: setupData.idleTimeoutInSecond.id,
  userCardId: appState.cardId ?? "",
  roomId: appState.roomId ?? "",
  roomName: appState.roomName,
})

export const Setup = () => {
  const { t } = useTranslation();  
  const appState = useSelector(appSelector)
  const [settingSteps, setSettingSteps] = useState(SetupConstants.StepsWithoutCompany)
  const [itemList, isItemListLoading, setItemList] = useAsyncState<Item[]>(SetupConstants.LanguageOptions)
  const [disabledConfirmBtn, setDisabledConfirmBtn] = useState(false)
  const [setupData, setSetupData] = useCompositeState<SetupData>({ ...SetupConstants.DefaultRoomData })
  const [activeStep, setActiveStep] = useState(settingSteps[0])

  const stepItemList = useMemo(() => settingSteps.map(key => ({ key }) satisfies StepItem<SettingStepEnum>), [settingSteps])

  const userCardId = appState.cardId ?? ""

  const ItemListBySettingStep = new Map<SettingStepEnum, MaybeAsyncFn<Item[]>>([
    [SettingStepEnum.Language, () => SetupConstants.LanguageOptions],
    [SettingStepEnum.Company, () => ApiService.getCompanyPrivileges(userCardId)],
    [SettingStepEnum.Location, () => ApiService.getLocations(setupData.company.id)],
    [SettingStepEnum.Area, () => ApiService.getAreas(setupData.company.id, setupData.location.id)
      .then(areas => [SetupConstants.NoAreaItem, ...areas])
    ],
    [SettingStepEnum.ConfirmTimeoutInSecond, () => SetupConstants.ConfirmTimeoutOptions],
    [SettingStepEnum.IdleTimeoutInSecond, () => SetupConstants.IdleTimeoutOptions],
  ])

  useEffect(() => {
    const init = async () => {
      const companyListItem = await ApiService.getCompanyPrivileges(userCardId);

      if (companyListItem.length > 1) {
        setSettingSteps(SetupConstants.StepsWithCompany)
        return
      }

      setupData.company = companyListItem[0]
    }

    init()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const itemListMaybeAsync = ItemListBySettingStep.get(activeStep)
    if (!itemListMaybeAsync) {
      return
    }

    setItemList(runMaybeAsyncFn(itemListMaybeAsync))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  const step: StepperProps<SettingStepEnum>["step"] = (item) => {
    setActiveStep(item.key)
  }

  const selectedItem = (item: Item) => {
    if (activeStep === SettingStepEnum.Language) {
      const language = parseLanguageIdToLanguageShort(item.id);
      i18n.changeLanguage(language);
    }

    const fieldName = FieldNameBySettingStep.get(activeStep)
    if (fieldName) {
      setSetupData(fieldName, item)
    }
    else if (!fieldName) {
      errorlog(`Setup: could not find fieldname for step:`, activeStep, `(${SettingStepEnum[activeStep]})`)
    }

    const nextStep = next(settingSteps, activeStep)
    if (!nextStep) {
      return
    }

    setActiveStep(nextStep)
  }

  const onConfirm = async () => {
    try {
      setDisabledConfirmBtn(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const id = await ApiService.createRoomSetting(setupDataToToolStoreRoom(setupData, appState));
  
      const ctx = getGlobalStateContext();
      const settings = await ApiService.getRoomSetting(ctx.roomId!);  // ctx.roomId can't be null or undefined here.
  
      // Like the old code.
      settings.logEnabled = true;
  
      ctx.updateRoomSettings(settings);
      appDispatch(AppActions.startSetupSuccessful());
    }
    finally {
      setDisabledConfirmBtn(false)
    }
  }

  const detailMessage = SetupConstants.MessageBySettingStep.get(activeStep) ?? ""
  const isCurrentStepSummarize = activeStep === SettingStepEnum.Summarize

  return (
    <div className="otg-setup">
      <Stepper
        activeStep={activeStep}
        steps={stepItemList}
        step={step}
        disabled={disabledConfirmBtn}
        allowStepBack
      />
      <div className="tab-content">
        <div className="tab-info">
          <h3 className="uppercase">{t('CaptionResource.UserPrivilege.35')}</h3>
          <span>{t(detailMessage)}</span>
          {isCurrentStepSummarize && (
            <ConfirmButton
              className="confirm-btn"
              onClick={onConfirm}
              disabled={disabledConfirmBtn}
              label={
                <>
                  <div className="confirm-text">
                    {t("AppInspect.ToolGateConfirm")}
                  </div>
                </>
              }
            />
          )}
        </div>

        <div className={css("tab-list-items", isCurrentStepSummarize && "summarize")}>
          {isCurrentStepSummarize
          ? (
            <div className="summarize">
              <div className="info">
                <div className="row">
                  <h3>{t("Settings.Language")}:</h3>
                  <span>{setupData.language.description}</span>
                </div>
                <div className="row">
                  <h3>{t("CaptionResource.Caption2")}:</h3>
                  <span>{setupData.company.description}</span>
                </div>
                <div className="row">
                  <h3>{t("CaptionResource.Caption7")}:</h3>
                  <span>{setupData.location.description}</span>
                </div>
                <div className="row">
                  <h3>{t("Equipment.Area")}:</h3>
                  <span>{setupData.area.description}</span>
                </div>
                <div className="row">
                  <h3>{t("AppInspect.ToolGateSessionTimeoutDuration")}:</h3>
                  <span>{setupData.confirmTimeoutInSecond.description}</span>
                </div>
                <div className="row">
                  <h3>{t("AppInspect.ToolGateIdleTimeoutDuration")}:</h3>
                  <span>{setupData.idleTimeoutInSecond.description}</span>
                </div>
              </div>
            </div>
          ) : (
            <List
              key={activeStep}
              items={itemList}
              isLoading={isItemListLoading}
              maximumItems={ListConstants.settingsMaxItemsPerPage}
              onRenderItem={(item) => (
                <div className="setup-info">
                  <div className="row">
                    {item.description} <span className="lowercase">{item.recommended &&
                      <>({t("AppInspect.Recommended")})</>
                    }</span>
                  </div>
                </div>
              )}
              onItemClick={selectedItem}
            />
          )}
        </div>
      </div>
    </div>
  );
};
