import { CommunicationObserver } from "../../App";
import { StateHandlerRunModeEnum } from "../../constants/enums/handler-type.enum";
import { EventState } from '../../constants/enums/state.enum';
import { SignalRConstants } from '../../constants/signalr.constants';
import { handlerlog } from '../../utils/log.utils';
import { and, hasSession, requireRoomId, isCheckInFlow, scanSessionIsOn, entranceDoorOpenEvent } from '../validators';
import { ExpectNextStates, StateHandler } from './common.handler';

export const EmergencyEntranceDoorCheckInSessionHandler: StateHandler<typeof SignalRConstants.Events.DoorChangedEvent> = {
  state: EventState.EmergencyEntranceDoorCheckInSession,
  event: SignalRConstants.Events.DoorChangedEvent,
  mode: StateHandlerRunModeEnum.Standalone,
  validator: and(
    requireRoomId,
    hasSession,
    isCheckInFlow,                // In check in flow
    scanSessionIsOn,              // and scan session is going on,
    entranceDoorOpenEvent(true),  // suddenly, the entrance door is opened, then it's an emergency exit.
  ),
  async handler(ctx, params) {
    ctx.doorSession.updateDoorState(params);
    
    handlerlog(this.state,
      `[EMERGENCY/CheckIn] cancelling check in flow.`
    );

    await ctx.session.emergencyCancel();
    await CommunicationObserver.resetToStandBy();

    return ExpectNextStates.AnyStates;
  },
};
