
import { css } from "../../utils/css.utils"
import "./index.scss"

export interface InfoScreenColumnsProps<T> {
  className?: string,
  header?: string | JSX.Element,
  leftColumn: JSX.Element,
  rightColumn: JSX.Element,
  isCenterScreen?: boolean,
}

export const InfoScreenColumns = <T,>(props: InfoScreenColumnsProps<T>) => {

  return (
    <div className={css("page-content info-screen-columns", props.className)}>
      {props.header &&
        <div className="page-header">{props.header}</div>
      }
      <div className={css("message", props.isCenterScreen && "center-screen")}>
        <div className="left">
          {props.leftColumn}
        </div>
        <div className="right">
          {props.rightColumn}
        </div>
      </div>
    </div>
  )
}