import { CommunicationObserver } from "../../App";
import { StateHandlerRunModeEnum } from "../../constants/enums/handler-type.enum";
import { EventState } from '../../constants/enums/state.enum';
import { SignalRConstants } from '../../constants/signalr.constants';
import { handlerlog } from '../../utils/log.utils';
import { and, hasSession, requireRoomId, isCheckOutFlow, scanSessionIsOn, warehouseDoorOpenEvent } from '../validators';
import { ExpectNextStates, StateHandler } from './common.handler';

export const EmergencyWarehouseDoorCheckOutSessionHandler: StateHandler<typeof SignalRConstants.Events.DoorChangedEvent> = {
  state: EventState.EmergencyWarehouseDoorCheckOutSession,
  event: SignalRConstants.Events.DoorChangedEvent,
  mode: StateHandlerRunModeEnum.Standalone,
  validator: and(
      requireRoomId,
      hasSession,
      isCheckOutFlow,               // In check out flow
      scanSessionIsOn,              // and scan session is going on,
      warehouseDoorOpenEvent(true), // suddenly, the warehouse door is opened, then it's an emergency exit.
    ),
  async handler(ctx, params) {
    ctx.doorSession.updateDoorState(params);
    
    handlerlog(this.state,
      `[EMERGENCY/CheckOut] cancelling check out flow.`
    );

    await ctx.session.emergencyCancel();
    await CommunicationObserver.resetToStandBy();

    return ExpectNextStates.AnyStates;
  },
};
