import { EventState } from '../constants/enums/state.enum';
import { DeviceSetup } from '../models/device-setup.model';
import { RoomInfo } from '../models/redux.model';
import { StatusParam } from '../models/signalr.model';
import { LocalStorageService } from '../services/local-storage.service';
import { AppActions } from '../states/app/app.slice';
import { appDispatch } from '../states/store';
import { IterUtils } from '../utils/functional.utils';
import { DoorSession } from './door-session';
import { ExpectNextStates } from './handlers/common.handler';
import { Session } from './session';
import { StandbyTimer } from './standby-timer';
import { StateHandlerResult } from './state-handler-observer';

export type RunFunctionAndIgnoreOtherEventsResult<T> =
  | { isIgnored: true }
  | {
      isIgnored: false;
      result: T;
    };

export type StateHandlerActiveId = number;

export class StateContext {
  private roomInfo: RoomInfo = {
    roomId: undefined,
    roomName: '',
  };

  private internalActiveHandlersCounter: number = 1;
  private activeHandlers: Map<StateHandlerActiveId, EventState> = new Map();
  private stateDeferFns: Map<StateHandlerActiveId, VoidFunction> = new Map();
  private roomConfigured: boolean = false;

  session: Session = new Session();
  doorSession: DoorSession = new DoorSession();

  /**
   * When expectNextStates is empty, accept any states.
   */
  private expectNextStates: EventState[] = [];


  get expectStates(): ReadonlyArray<EventState> {
    return this.expectNextStates;
  }

  get roomId() {
    return this.roomInfo.roomId;
  }

  get roomName() {
    return this.roomInfo.roomName;
  }

  get isRoomConfigured(): boolean{
    return this.roomConfigured;
  }

  isStateExpected(state: EventState): boolean {
    return this.expectNextStates.length === 0 || this.expectNextStates.includes(state);
  }

  isStateExpectedExact(states: EventState[]): boolean {
    return this.expectNextStates.length === states.length
      && this.expectNextStates.every(state => states.includes(state))
  }

  updateRoomInfo(roomInfo: RoomInfo) {
    this.roomInfo = roomInfo;
    LocalStorageService.saveRoomInfo(roomInfo);
    appDispatch(AppActions.setRoomInfo(roomInfo));
  }

  updateRoomInfoFromStatus(signalRStatusParam: StatusParam['status']) {
    const roomInfo = {
      roomId: signalRStatusParam.sluiceId,
      roomName: signalRStatusParam.sluiceDescription
    };

    this.updateRoomInfo(roomInfo);
  }

  /**
   * Check if there is any state handler running.
   */
  isStateHandlerActive(state: EventState): boolean {
    return IterUtils.any(this.activeHandlers.values(), activeState => activeState === state);
  }

  updateExpectNextStates(state: StateHandlerResult) {
    if (state === ExpectNextStates.Unchanged) {
      return;
    }

    this.expectNextStates = state;
  }

  /** **Please do not use this!** */
  registerActiveHandler(handlerState: EventState): StateHandlerActiveId {
    const activeHandlerNo = this.internalActiveHandlersCounter;
    
    this.internalActiveHandlersCounter += 1; // Good person uses `+= 1`, don't use those `++` things.

    this.activeHandlers.set(activeHandlerNo, handlerState);

    return activeHandlerNo;
  }

  /** **Please do not use this!** */
  removeActiveHandler(activeHandlerNo: StateHandlerActiveId) {
    if (this.activeHandlers.has(activeHandlerNo)) {
      this.activeHandlers.delete(activeHandlerNo);
    }
  }

  /** **Please do not use this!** */
  setStateDeferFn(activeId: StateHandlerActiveId, fn: VoidFunction) {
    this.stateDeferFns.set(activeId, fn);
  }

  /** **Please do not use this!** */
  getStateDeferFn(activeId: StateHandlerActiveId) {
    return this.stateDeferFns.get(activeId);
  }

  setRoomConfigured(state: boolean){
    this.roomConfigured = state;

    // Automatically cancel when the room is not configured.
    if (!this.roomConfigured) {
      StandbyTimer.cancel();
    }
  }

  updateRoomSettings(settings: DeviceSetup) {
    this.setRoomConfigured(true);

    LocalStorageService.setRoomSettings(settings);
    this.session.reloadSettings();
  }
}
