import { useState } from "react"
import { css } from "../../utils/css.utils"
import "./index.scss"

export interface PopoverProps {
  className?: string,
  content: JSX.Element,
  children: JSX.Element,
}

export const Popover = (props: PopoverProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [shouldRemoveDom, setShouldRemoveDom] = useState(!isOpen)

  const toggleTooltip = () => {
    const willOpenPopover = !isOpen

    // I think I should use animationend event, but I don't have time for that now :'(
    // Future people, do this for me plis :D

    setTimeout(() => setIsOpen(willOpenPopover), 0)
    
    if (!willOpenPopover) {
      setTimeout(() => setShouldRemoveDom(true), 300)
      return
    }

    setShouldRemoveDom(false)
  }

  return (
    <div className={css("otg-popover", props.className)}>
      <div className="otg-popover-display" onClick={toggleTooltip}>
        {props.children}
      </div>

      {!shouldRemoveDom &&
        <div className={css("otg-popover-content", isOpen && "opened")}>
          {props.content}
        </div>
      }
    </div>
  )
}
