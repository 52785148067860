import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { MainBody } from "./components/MainBody/MainBody"
import { MainNav } from "./components/MainNav/MainNav"
import { Modal } from "./components/Modal/Modal"
import { GlobalErrorAppEnum } from './constants/enums/global-error.enum'
import { getGlobalErrorContent } from './constants/global-error.constant'
import { MainLayout } from "./pages/MainLayout/MainLayout"
import { CommunicationObserverClass } from "./signalr/communication-observer"
import { StateHandlerObserverClass } from "./signalr/state-handler-observer"
import { apiErrorSelector, appSelector } from "./states/app/app.slice"
import { Timer } from "./utils/timer.utils"

export const ConfirmTimer = new Timer("confirm-timer")
export const InactivityTimer = new Timer("inactivity-timer")
export const StateHandlerObserver = new StateHandlerObserverClass()
export const CommunicationObserver = new CommunicationObserverClass(StateHandlerObserver);

export const App = () => {
  const {t} = useTranslation();
  // const f = useFormatTranslation(t);
  const apiErrorModalState = useSelector(apiErrorSelector);
  const appState = useSelector(appSelector);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

    const getGlobalError = () => {
      if (!isOnline) return GlobalErrorAppEnum.ClientAppIsOffline;
  
      if (!appState.isSignalRServerConnected) return GlobalErrorAppEnum.DisconnectedSignalR;
  
      if (!appState.isApiOnline) return GlobalErrorAppEnum.ApiIsOffline;
  
      if (apiErrorModalState.isShow) return GlobalErrorAppEnum.UnknownError;
  
      return GlobalErrorAppEnum.None;
    }

  const globalError = getGlobalError();
  const isShowErrorModal = globalError !== GlobalErrorAppEnum.None;
  const globalErrorContent = getGlobalErrorContent(globalError);

  // const showInactiveModal =
  //   appState.showInactivePopup &&
  //   (appState.appState === AppStateEnum.CheckIn || appState.appState === AppStateEnum.CheckOut);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    }

    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    }
  }, [isOnline]);

  // useEffect(() => {
  //   if (showInactiveModal) {
  //     return
  //   }

  //   InactivityTimer.cancel()
  // }, [showInactiveModal])

  return (
    <MainLayout>
      <MainNav />
      <MainBody />
      <Modal 
        isOpen={isShowErrorModal}
        header={t(globalErrorContent?.headerKey as string)}
        content={globalErrorContent?.isTranslate
          ? t(globalErrorContent?.messageKey as string)
          : globalErrorContent?.messageKey}
        onDismiss={globalErrorContent?.onDissmiss}
      />
      <ToastContainer />
    </MainLayout>
  )
}
