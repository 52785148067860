import { Icon } from "../Icon/Icon"
import "./index.scss"

export const TraditionalSpinner = () => {
  return (
    <div className="otg-traditional-spinner">
      <div className="spinner-inner">
        <Icon iconName="ProgressRingDots" size="100px" />
      </div>
    </div>
  )
}

export const BarSpinner = () => {
  return (
    <svg className="otg-bar-spinner">
      <circle>
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="-90;810"
          keyTimes="0;1"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke-dashoffset"
          values="0%;0%;-157.080%"
          calcMode="spline"
          keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0"
          keyTimes="0;0.5;1"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke-dasharray"
          values="0% 314.159%;157.080% 157.080%;0% 314.159%"
          calcMode="spline"
          keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0"
          keyTimes="0;0.5;1"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  )
}

export const DotsSpinner = () => {
  return (
    <div className="otg-dots-spinner">
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
    </div>
  )
}

export const Spinner = BarSpinner
