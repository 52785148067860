import { ApiResponseErrorCodeEnum } from '../constants/enums/api-response-error-code.enum';
import { ApiResponseError } from '../models/api-response.model'

export type Result<T, E> = { isOk: true; data: T } | { isOk: false; data: E }

export type ApiResponseResult<T> = Result<T, ApiResponseError>;

const promiseToResult = async <T>(promise: Promise<T>): Promise<ApiResponseResult<T>> => {
  return promise
    .then((resp) => ({ isOk: true, data: resp } as ApiResponseResult<T>))
    .catch(
      (err) =>
        ({
          isOk: false,
          data: {
            detail: err?.detail,
            errorCode: err?.errorCode ?? ApiResponseErrorCodeEnum.UnknownCode,
            originError: err,
          } as ApiResponseError,
        } as ApiResponseResult<T>)
    );
};

export const Result = {
  fromAPIPromise: promiseToResult,
};
