import { TimerConstants } from "../constants/timer.constants";
import { emptyVoidFunction, runMaybeAsyncFn, SetIntervalType } from "./functional.utils";
import { errorlog, log } from "./log.utils";

export type TimerTickFn = (nthSecond: number) => void

export class Timer {
  private remainingSeconds: number = 0
  private timerLength: number = 0
  private tickFns: TimerTickFn[] = []
  private timerFinishedFns: VoidFunction[] = []
  private timerId: SetIntervalType | undefined = undefined
  private timerName: string = ''
  private isStarted: boolean = false

  // This is used to track 
  private stopCurrentTimer: VoidFunction = emptyVoidFunction

  get isRunning() {
    return this.isStarted
  }

  get isRunIndefinitely() {
    return this.timerLength === TimerConstants.NoTimeoutValue
  }

  get length() {
    return this.timerLength
  }

  get remaining() {
    return this.remainingSeconds
  }

  get name() {
    return this.timerName
  }

  constructor(name: string) {
    this.timerName = name
  }

  startTimer(seconds: number) {
    seconds = Math.floor(seconds)

    if (seconds < 0) {
      errorlog(`Timer[${this.timerName}]::startTimer: invalid seconds value:`, seconds)
      return
    }

    this.isStarted = true

    if (seconds === 0) {
      log(`Timer[${this.timerName}]::startTimer: no timeout! (I mean, this won't do anything.)`)
      return
    }

    this.timerLength = seconds
    this.run()
  }

  /**
   * Callback that run every tick (1 second).
   * 
   * The registered function should be lightweight to not block timer.
   */
  onTick(fn: TimerTickFn) {
    this.tickFns.push(fn)
  }

  /**
   * Callback that run when timed out. This callback won't be called if the timer is cancelled.
   */
  onFinished(fn: VoidFunction) {
    this.timerFinishedFns.push(fn)
  }

  private async run() {
    // Clear previous timer if any.
    this.clearInterval()

    this.remainingSeconds = this.timerLength

    if (this.remainingSeconds <= 0) {
      return
    }

    // Tick before setInterval because setInterval delayed before execution.
    this.tick()

    const currentTimerId = setInterval(async () => {
      this.remainingSeconds -= 1

      this.tick()

      if (this.remainingSeconds <= 0) {
        // Only run this when timer is run out.
        this.stopTimerAndClearAll() 
        return
      }
    }, TimerConstants.BaseTimerInMillis)

    this.timerId = currentTimerId
    this.stopCurrentTimer = () => {
      clearInterval(currentTimerId)
    }
  }
  
  async stopTimerAndClearAll() {
    log(`Timer[${this.timerName}]::stopTimerAndClearAll`)

    this.clearInterval()
    
    for (const fn of this.timerFinishedFns) {
      await runMaybeAsyncFn(fn)
    }
    
    this.clear()
  }

  restart(shouldLog = true) {
    if (this.isRunIndefinitely) {
      return;
    }
    
    shouldLog && log(`Timer[${this.timerName}]::restart: restarting timer.`)
    this.stopCurrentTimer()
    this.run()
  }

  cancel() {
    log(`Timer[${this.timerName}]::cancel: just clear timer and callbacks. This won't run any callback!`)

    this.clear()
  }

  private clearInterval() {
    clearInterval(this.timerId)

    this.timerId = undefined
  }

  private clear() {
    this.clearInterval()
    
    this.remainingSeconds = 0
    this.timerLength = 0
    this.tickFns = []
    this.timerFinishedFns = []
    this.isStarted = false
  }

  private tick() {
    log(`Timer[${this.timerName}]::tick: second:`, this.remainingSeconds)
    this.tickFns.forEach(fn => fn(this.remainingSeconds))
  }
}
