
export enum EventState {
  Status = "Status",
  CardReaderReadEntrance = "CardReaderReadEntrance",
  EntranceDoorOpenedForCheckin = "EntranceDoorOpenedForCheckin",
  EntranceDoorClosedForCheckin = "EntranceDoorClosedForCheckin",
  WarehouseDoorClosedForCheckin = "WarehouseDoorClosedForCheckin",
  RfidReaderRead = "RfidReaderRead",
  CardReaderReadWarehouse = "CardReaderReadWarehouse",
  WarehouseDoorOpenedForCheckout = "WarehouseDoorOpenedForCheckout",
  WarehouseDoorClosedForCheckout = "WarehouseDoorClosedForCheckout",
  EntranceDoorClosedForCheckout = "EntranceDoorClosedForCheckout",

  RoomConfigurateCardReaderReadEntrance = "RoomConfigurateCardReaderReadEntrance",
  RoomConfigurateCardReaderReadWarehouse = "RoomConfigurateCardReaderReadWarehouse",

  DoorButtonPressedNoSession = "DoorButtonPressedNoSession",
  DoorButtonPressedBeforeCheckInScanningSession = "DoorButtonPressedBeforeCheckInScanningSession",
  DoorButtonPressedBeforeCheckOutScanningSession = "DoorButtonPressedBeforeCheckOutScanningSession",

  EntranceDoorButtonPressedInCheckInSession = "EntranceDoorButtonPressedInCheckInSession",
  WarehouseDoorButtonPressedInCheckInSession = "WarehouseDoorButtonPressedInCheckInSession",
  EntranceDoorButtonPressedInCheckOutSession = "EntranceDoorButtonPressedInCheckOutSession",
  WarehouseDoorButtonPressedInCheckOutSession = "WarehouseDoorButtonPressedInCheckOutSession",
  
  EmergencyEntranceDoorBeforeScanningCheckInSession = "EmergencyEntranceDoorBeforeScanningCheckInSession",
  EmergencyWarehouseDoorBeforeScanningCheckInSession = "EmergencyWarehouseDoorBeforeScanningCheckInSession",

  EmergencyEntranceDoorBeforeScanningCheckOutSession = "EmergencyEntranceDoorBeforeScanningCheckOutSession",
  EmergencyWarehouseDoorBeforeScanningCheckOutSession = "EmergencyWarehouseDoorBeforeScanningCheckOutSession",

  EmergencyEntranceDoorCheckInSession = "EmergencyEntranceDoorCheckInSession",
  EmergencyWarehouseDoorCheckInSession = "EmergencyWarehouseDoorCheckInSession",
  EmergencyEntranceDoorCheckOutSession = "EmergencyEntranceDoorCheckOutSession",
  EmergencyWarehouseDoorCheckOutSession = "EmergencyWarehouseDoorCheckOutSession",
}
