import { HubConnectionBuilder } from "@microsoft/signalr";
import { SignalRConstants } from "../constants/signalr.constants";
import { LocalStorageService } from "../services/local-storage.service";
import { AppActions } from "../states/app/app.slice";
import { appDispatch } from "../states/store";
import { errorlog, log } from "../utils/log.utils";

// This URL is saved to track which URL is being used and for debugging purpose.
// The URL may be changed in the localStorage and it doesn't reflect the change right away.
const signalRUrlUsed = { value: LocalStorageService.getSettings().signalRUrl };

export const buildSignalRConnection = () => {
  signalRUrlUsed.value = LocalStorageService.getSettings().signalRUrl;

  return new HubConnectionBuilder()
    .withUrl(signalRUrlUsed.value)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: function (retryContext) {
        errorlog('RETRY REASON:', JSON.parse(JSON.stringify(retryContext.retryReason)))
        return SignalRConstants.Default.RetryDelayInMilliseconds;
      },
    })
    .build();
}

export const handleSignalRReconnecting = (error: any) => {
  log('SignalR disconnected...', error);
  appDispatch(AppActions.setSignalRConnectionState(false));
}

export const handleSignalRReconnected = (connectionId: string | undefined) => {
  log('SignalR reconnected...', connectionId);
  appDispatch(AppActions.setSignalRConnectionState(true))
}


