import { configureStore } from "@reduxjs/toolkit";
import { AppReducer } from "./app/app.slice";
import { CheckInOutReducer } from "./app/check-in-out-state.slice";
import { LogServerSideReducer } from "./app/log-server-side.slice";

export type RootState = ReturnType<typeof store.getState>

export const store = configureStore({
    reducer: {
        app: AppReducer,
        checkInOut: CheckInOutReducer,
        logServerSide: LogServerSideReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

export const appDispatch = store.dispatch