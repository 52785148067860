import { CommunicationObserver } from "../../App";
import { EventState } from "../../constants/enums/state.enum";
import { SignalRConstants } from "../../constants/signalr.constants";
import { CheckInOutActions } from "../../states/app/check-in-out-state.slice";
import { appDispatch } from "../../states/store";
import { and, hasSession, mustBeExpected, entranceDoorOpenEvent, requireRoomId } from "../validators";
import { ExpectNextStates, StateHandler } from "./common.handler";

export const EntranceDoorClosedForCheckinHandler: StateHandler<typeof SignalRConstants.Events.DoorChangedEvent> = {
  state: EventState.EntranceDoorClosedForCheckin,
  event: SignalRConstants.Events.DoorChangedEvent,
  validator: and(requireRoomId, hasSession, mustBeExpected, entranceDoorOpenEvent(false)),
  async handler(ctx, params) {
    // Entrance door state is closed.
    ctx.doorSession.updateDoorState(params);

    if (ctx.doorSession.isWarehouseDoorOpened) {
      // Warehouse door is opened, expect it to close.
      return ExpectNextStates.WarehouseDoorClosedForCheckInOrEmergencyOnEntranceDoor;
    }

    await CommunicationObserver.rfidReaderStart();

    await ctx.session.startScanAndRegisterEndSession();

    appDispatch(CheckInOutActions.switchToWaitForConfirmingResults());

    return ExpectNextStates.RfidReaderRead;
  },
};
