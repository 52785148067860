import { StateHandlerRunModeEnum } from "../../constants/enums/handler-type.enum";
import { EventState } from "../../constants/enums/state.enum";
import { SignalRConstants } from "../../constants/signalr.constants";
import { handlerlog } from "../../utils/log.utils";
import { and, entranceDoorButtonEvent, hasSession, isCheckInFlow, roomConfigured, scanSessionIsOn } from "../validators";
import { ExpectNextStates, StateHandler } from "./common.handler";

export const EntranceDoorButtonPressedInCheckInSessionHandler: StateHandler<typeof SignalRConstants.Events.DoorButtonPressed> = {
  state: EventState.EntranceDoorButtonPressedInCheckInSession,
  event: SignalRConstants.Events.DoorButtonPressed,
  mode: StateHandlerRunModeEnum.Standalone,
  validator: and(
    roomConfigured,
    hasSession,
    scanSessionIsOn,          // Both doors are closed!
    entranceDoorButtonEvent,  // Only accept the warehouse button (btw, we don't have to handle entrance door for check in flow).
    isCheckInFlow,            // Check in flow only!
  ),
  async handler(ctx, params) {
    handlerlog(this.state, `${params.door} door button has been pressed while checking in, cancelling session.`);

    ctx.session.cancel();

    return ExpectNextStates.AnyStates;
  },
};
