import { EventState } from "../../constants/enums/state.enum";
import { SignalRConstants } from "../../constants/signalr.constants";
import { ApiService } from "../../services/api.service";
import { CheckInOutActions } from "../../states/app/check-in-out-state.slice";
import { appDispatch } from "../../states/store";
import { handlerlog } from "../../utils/log.utils";
import { twoDoorClosed, and, hasSession, mustBeExpected, requireRoomId, scanSessionIsOn } from "../validators";
import { ExpectNextStates, StateHandler } from "./common.handler";

export const RfidReaderReadHandler: StateHandler<typeof SignalRConstants.Events.RfidReaderRead> = {
  state: EventState.RfidReaderRead,
  event: SignalRConstants.Events.RfidReaderRead,
  validator: and(requireRoomId, hasSession, mustBeExpected, twoDoorClosed, scanSessionIsOn),
  async handler(ctx, params) {
    const equipment = await ApiService.getEquipmentByGlobalId(params.serialNumber);
    
    // Scan session maybe end by the time the API returns, check it one more time to make sure we don't
    // accidentally insert equipment into new session.
    if (!ctx.session.isScanning) {
      // The API might take some time to complete, so the confirm timer may already be ended.
      // Return ExpectNextStates.Unchanged to ensure this handler doesn't override the expect next states.
      handlerlog(this.state, "scanning phase ended while waiting for getEquipmentByGlobalId API to complete");
      return ExpectNextStates.Unchanged;
    }

    ctx.session.registerScannedId(params.serialNumber);
    
    // We accept all the result from the API, it will displayed as "Not found equipment".
    appDispatch(CheckInOutActions.addEquipmentInfo(equipment));

    // The NextExpectState auto override when the scan session is timed out.
    return ExpectNextStates.RfidReaderRead;
  },
};
