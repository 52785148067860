import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { CommonPageConstants } from "../../constants/common-page.constants"
import { AppStateEnum } from "../../constants/enums/app-state.enum"
import { appStateSelector } from "../../states/app/app.slice"
import { translateUndefined } from "../../utils/language.utils"
import { errorlog } from "../../utils/log.utils"
import { CardReaderRead } from "../CardReaderRead/CardReaderRead"
import { CheckInOut } from "../CheckInOut/CheckInOut"
import { InfoScreen } from "../InfoScreen/InfoScreen"
import { Setup } from "../Setup/Setup"
import { SetupSuccessful } from "../SetupSuccessful/SetupSuccessful"
import "./index.scss"

export const MainBody = () => {
  const [t] = useTranslation()
  const appState = useSelector(appStateSelector)

  let Component: JSX.Element = <></>

  const tUndefined = (key: Parameters<typeof translateUndefined>[1]) => translateUndefined(t, key)

  switch (appState) {
    case AppStateEnum.CheckInOutComplete:
    case AppStateEnum.RoomNotConfigured:
    case AppStateEnum.NoLicense:
    case AppStateEnum.SignalRReconnected:
      const content = CommonPageConstants.contentByAppState.get(appState) 
      Component = (
        <InfoScreen
          message={tUndefined(content?.messageKey)}
          header={tUndefined(content?.headerKey)}
          icon={content?.icon}
        />
      )
      break

    case AppStateEnum.CheckIn:
    case AppStateEnum.CheckOut:
      Component = <CheckInOut />
      break

    case AppStateEnum.CardReaderReadCheckIn:
    case AppStateEnum.CardReaderReadCheckOut:
      Component = <CardReaderRead />
      break  
 
    case AppStateEnum.Setup:
      Component = <Setup />
      break
     case AppStateEnum.SetupSuccessful:
      Component = <SetupSuccessful />
      break
    default:
      errorlog(`component=${MainBody.name}: invalid appState: "${appState}"`)
      break
  }

  return (
    <div className="otg-main-body">
      {Component}
    </div>
  )
}
