import { useTranslation } from 'react-i18next';
import './index.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InfoScreen } from '../InfoScreen/InfoScreen';
import { ToolGateIcon } from '../Logo/Logo';
import { checkInOutInfoSelector } from '../../states/app/check-in-out-state.slice';

export const SetupSuccessful = () => {
  const checkInOutInfo = useSelector(checkInOutInfoSelector)
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      checkInOutInfo.onConfirmResult();
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <InfoScreen
    message={t('AppInspect.ToolGateSettingConfigSuccessful')}
    header={t('CaptionResource.UserPrivilege.35')}
    icon={<ToolGateIcon width="200px"  height="200px" />}
  />
  );
};
